.sutaAdminSection {
  display: flex;
  justify-content: space-between;
  // width: 75%;
  gap: 3rem;
}

.sutaAdminBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.blockContent {
  display: flex;
  background-color: white;
  padding: 7px 10px;
  gap: 10px;
}

.table {
  border: transparent;
  tr {
    td:not(:first-child),
    th:not(:first-child) {
      text-align: center;
      border-left: 25px solid #ebebeb;
    }
  }
}

@media only screen and (max-width: 1240px) {
  .sutaAdminSection {
    // width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .sutaAdminSection {
    flex-wrap: wrap;
  }
}
