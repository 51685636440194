.tableContainer {
  table {
    text-align: center;
    .description {
      // width: 100%;
    }
    td {
      vertical-align: middle;
    }
    thead {
      th {
        white-space: nowrap;
        vertical-align: middle;
      }
      th:not(:last-child) {
        border-right: 0.1px solid black;
      }
    }
  }
}

.dynamicName {
  color: red;
  margin-left: 41%;
  margin-top: -2%;
}

@media (max-width: 410px) {
  .dynamicName {
    color: red;
    display: flex;
    margin-left: 0px;
    align-items: center;
    justify-content: center;
    margin-top: -2%;
  }
}

@media (max-width: 1100px) {
  .dynamicName {
    color: red;
    display: flex;
    margin-left: -18%;
    align-items: center;
    justify-content: center;
    margin-top: -1%;
  }
}

.dynamicComment {
  color: red;
  margin-left: 41%;
  margin-top: 1%;
}

@media (max-width: 410px) {
  .dynamicComment {
    color: red;
    display: flex;
    margin-left: 0px;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
  }
}

.alignbtn {
  display: flex;
  justify-content: center;

  gap: 20px;
  .loaderPosition {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .alignbtn {
    display: grid;
    justify-content: center;
    .loaderPosition {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
