.sectionContentContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.sectionContent {
  display: flex;
  gap: 6rem;
  justify-content: space-between;
  > div {
    width: 100%;
  }
}

.profitabilityContainer {
  .table {
    // border: transparent;
    text-align: center;
    thead {
      th {
        background-color: rgb(182, 227, 148) !important;
      }
    }
    tbody {
      th {
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .sectionContent {
    gap: 2rem;
  }
}

.approve_button {
  border-radius: 25px;
  background-color: #1ad60c !important;
  border-color: #1ad60c !important;
  // box-shadow: 2px 4px 12px #008DDA;
  color: white !important;
  font-size: 1rem;
  font-weight: 800;
}
.reject_button {
  border-radius: 25px;
  background-color: #f71d1d !important;
  border-color: #f71d1d !important;
  // box-shadow: 2px 4px 12px #008DDA;
  color: white !important;
  font-size: 1rem;
  font-weight: 800;
}
