.sectionContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  input {
    max-width: 100px;
    max-height: 35px;
    text-align: center;
  }
}

.wcModifiersContainer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.blockContent {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.carrierValue {
  width: 100px;
  height: 35px;
  text-align: center;
}

.table {
  // border: transparent;
  margin-bottom: 0;
  text-align: center;
  thead {
    th:not(:last-child) {
      border-right: 0.1px solid black;
    }
  }
  tbody {
    td {
      vertical-align: middle;
    }
  }
}

.sutaAdminModifiersContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  label {
    display: flex;
    align-items: center;
    input {
      margin-right: 0.4rem;
    }
  }
}

.overallRow1 {
  display: flex;
  justify-content: space-between;
}

.overallRow3 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.addBrokerSection {
  color: rgb(97, 154, 79);
  cursor: pointer;
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 0.5rem;
}

.removeBrokerSection {
  color: red;
  cursor: pointer;
  margin-left: 2rem;
  display: flex;
}
