@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Poppins:wght@700&display=swismobileap");

//design for the new quote component
input[type="number"] {
  -moz-appearance: textfield;
}

// @font-face {
//   font-family: "MainFont";
//   src: url("../fonts/SinkinSansLight.ttf");
// }
@font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
    url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("../fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

.font-family-montserrat-bold {
  font-family: "MontserratBold";
}

.font-family-montserrat-semi-bold {
  font-size: 15px;
  font-family: "MontserratSemiBold";
}

.font-family-montserrat-medium {
  font-family: "MontserratMedium";
}

.font-family-montserrat-regular {
  font-family: "MontserratRegular";
}

html body {
  max-width: 100%;
  overflow-x: hidden;
}

input {
  padding: 0.5rem;
}

.per5 {
  width: 5%;
  max-width: 5%;
}

.per7 {
  width: 6%;
  max-width: 6%;
}

.per20 {
  width: 20%;
  max-width: 20%;
}

.per10 {
  max-width: 10%;
  width: 10%;
}

body {
  font-family: "MontserratRegular";
  -webkit-font-smoothing: antialiased;
  color: black;
  // font-size: 13.7px;
  font-size: 15px;
}

.lib_dashboard {
  padding-top: 50px;
}

.home1 {
  word-break: break-all;
  // font-family: "Rokkitt", serif;
  font-weight: 400;
  line-height: 1.1;

  .header {
    width: 100%;

    .logos-container {
      padding: 0.4rem 0;
      margin-left: 2.5rem;

      .solve-logo-container {
        height: 4.65rem;

        img {
          max-height: 100%;
        }
      }

      .insurecomp-logo-container {
        height: 3.28rem;
        margin-top: -1.4rem;

        img {
          max-height: 100%;
        }
      }
    }
  }

  .image-block {
    cursor: pointer;
    position: relative;
    height: 22rem;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    .image-text-container {
      padding: 1rem 0;
      background: rgba(24, 54, 80, 0.6);
      position: absolute;

      .image-text {
        font-size: 3.125rem;
        font-weight: bolder;
        color: white;
      }
    }
  }

  .stats-1 {
    width: 100%;
    margin-bottom: 4rem;

    .stat-1-1,
    .stat-1-2 {
      flex: 1;

      span {
        font-weight: bolder;
        font-size: 2.5rem;
      }

      img {
        height: 5.5rem;
        max-height: 100%;
        max-width: 100%;
      }
    }

    .stat-1-1 {
      img {
        height: 6rem;
      }

      span {
        margin-left: 1.4rem;
      }
    }
  }

  .confirm-quote {
    width: 100%;

    button {
      border-radius: 10px;
      box-shadow: 2px 4px 10px #23b4bd;
      font-size: 3.125rem;
      font-weight: 700;
      color: white;
      background-color: #23b4e8;
      width: 35rem;
      padding: 0.5rem 0;
    }
  }

  .services {
    padding: 3rem 0 4rem 0;

    .services-header {
      font-size: 4rem;
      font-weight: bolder;
      margin-bottom: 3rem;
    }

    .services-list-block {
      .services-list-1 {
        margin-bottom: 3rem;

        .services-1-1,
        .services-1-2,
        .services-1-3 {
          flex: 1;

          span {
            font-weight: bolder;
            font-size: 2.5rem;
          }

          img {
            margin-bottom: 1.5rem;
            height: 5.5rem;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }

      .services-list-2 {
        .services-2-1,
        .services-2-2,
        .services-2-3 {
          flex: 1;

          span {
            font-weight: bolder;
            font-size: 2.5rem;
          }

          img {
            margin-bottom: 1.5rem;
            height: 5.5rem;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .stats-banner {
    background-color: #183650;
    padding: 2.5rem 0;

    .stats-banner-item-1,
    .stats-banner-item-2,
    .stats-banner-item-3 {
      flex: 1;
      font-size: 2.5rem;
      font-weight: bolder;
      color: white;
    }
  }

  .testimonials {
    font-size: 2rem;
    margin: 2rem;

    .testimonials-text {
      font-style: italic;
    }
  }
}

.logout-div {
  margin-right: 2.5rem;
  font-size: 16px;
}

.logout-div:hover {
  text-decoration: underline;
  cursor: pointer;
}

.home2 {
  font-family: "Poppins", serif;
  font-weight: 400;
  line-height: 1.1;

  .footer {
    width: 100%;
    height: 3rem;
    background-color: #183650;
    font-size: 2rem;

    a,
    span {
      margin-right: 2.5rem;
      color: white;
    }

    span:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .header {
    width: 100%;

    .logos-container {
      padding: 0.4rem 0;
      margin-left: 2.5rem;

      .solve-logo-container {
        height: 6.65rem;

        img {
          max-height: 100%;
        }
      }

      .insurecomp-logo-container {
        height: 3.28rem;
        margin-top: -1.4rem;

        img {
          max-height: 100%;
        }
      }
    }
  }

  .image-block {
    cursor: pointer;
    position: relative;
    height: 22rem;
    overflow: hidden;

    img {
      max-width: 100%;
    }

    .image-text-container {
      padding: 1rem 0;
      background: rgba(24, 54, 80, 0.6);
      position: absolute;

      .image-text {
        font-size: 3.125rem;
        font-weight: bolder;
        color: white;
      }
    }
  }

  .stat-2-1 {
    margin-right: 2.5rem;
    word-break: break-word;

    span {
      width: inherit;
      font-weight: bolder;
      font-size: 2.5rem;
    }

    img {
      height: 5.5rem;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .confirm-quote {
    width: 100%;

    button {
      border-radius: 10px;
      box-shadow: 2px 4px 10px #eea236;
      font-size: 3.125rem;
      font-weight: 700;
      color: white;
      background-color: #ffd13f;
      border-color: #eea236;
      width: 35rem;
      padding: 0.5rem 0;
    }
  }

  .stats-banner {
    background-color: #183650;
    padding: 2.5rem 0;

    .stats-banner-item-1,
    .stats-banner-item-2,
    .stats-banner-item-3 {
      flex: 1;
      font-size: 2.5rem;
      font-weight: bolder;
      color: white;
    }
  }

  .testimonials {
    font-size: 2rem;
    margin: 2rem;

    .testimonials-text {
      font-style: italic;
    }
  }
}

class-desc-select .class-desc-select__menu {
  height: fit-content;
  width: fit-content;
  max-width: 500px;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
}

.contentHolder-history {
  min-height: calc(100vh - 15.75rem);
}

@mixin cmprQts {
  //style of the background image:
  background-image: url("../images/backimage4.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;
  background-attachment: sticky;
  padding-left: 0%;

  .quote-table {
    .quote-table-company-name {
      padding-right: calc(2rem + 25px);
    }

    .head-row {
      align-items: center;
      justify-content: space-between;
    }

    .hr1 {
      color: #2c2e33;
      font-size: 26px;
      font-weight: 500;

      &.quote-table-company-name {
        // font-family: "Overpass", sans-serif;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .hr2 {
      color: #2c2e33;
      width: 70%;
      margin-top: 1rem;
      margin-bottom: 1rem;

      span {
        color: #5f636b;
      }
    }

    .hr3 {
      margin-bottom: 1.5rem;
      color: #2c2e33;
    }

    .body-row {
      padding-left: 6px;
      margin-top: 2rem;

      // justify-content: left;
      .table {
        margin-bottom: 7rem;
        position: relative;
        right: 2rem;
        border-collapse: separate;
        border-spacing: 25px 0px;

        thead {
          th {
            color: #2c2e33;
            position: relative;
            right: 0.6rem;
            font-size: 16px;
          }
        }

        tbody {
          padding-left: 5px;
          padding-right: 5px;
          font-size: 18px;

          td,
          th {
            width: 25%;
            border: 1px solid white;
            // background-color: white;
            color: #2c2e33;
            font-size: 18px;
            font-weight: normal;

            div {
              height: 100%;

              label {
                margin-bottom: 0;
              }
            }
          }

          td {
            label {
              font-size: 22px;
              font-weight: 800;
              color: #1463ac;
              margin-bottom: 0;
            }

            .best {
              font-size: 14px;
              margin-left: 1rem;
              color: #038b00;
            }

            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }

            .uw {
              font-size: 14px;
              margin-left: 1rem;
            }

            input {
              margin: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.new-navbar {
  //border: 1px solid black;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  height: 4.75rem;
  background-color: #003764;
  color: white;
  font-weight: 600;
  font-size: 16px;

  .peo-header {
    color: #ff5a54;
  }
  .pride-logo {
    margin-left: 2.2rem;
  }

  .insurecomp-logo {
    margin-right: 2.2rem;
  }

  .forBig {
    margin-left: auto;
  }

  .new-logo {
    // margin-top: 0.5rem;
    border: 1px solid black;
    height: 3.375rem;
    // margin-left: 2.2rem;
  }

  .boxed-logo {
    background-color: #fff;
    padding: 20px;
    height: 125px;
    margin: 5px 20px;
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-left: 40px;
  }

  button {
    border: none;
    color: white;
    background: none;
    font-weight: 600;
  }

  span {
    color: white;
    font-weight: 600;
  }

  ul {
    li {
      // padding-bottom: 10px;
      margin-right: 1rem;
      margin-left: 1rem;
    }
  }
}

.secDesc {
  text-align: center;
  font-size: 16px;
  margin-bottom: 30px;
}

#small-loader {
  width: fit-content;
  height: fit-content;

  /* margin-top: 1rem; */
  > img {
    width: 2rem;
  }
}

#rate1,
#rate2 {
  margin-bottom: 5px;
}

#compareQuote,
#underwriting-table {
  .quote-action-buttons {
    gap: 2rem;
  }

  .btnSubmits {
    margin-top: 2rem;
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #ffd13f;
    border-color: #eea236;
    box-shadow: none;
    color: white;
    // font-size: 14px;
    font-weight: 800;
  }

  .btnView {
    width: 210px;
    height: 40px;
    border-radius: 5px;
    background-color: #1463ac;
    margin-right: 1rem;
    margin-top: 2rem;
    // font-size: 14px;
    font-weight: 800;
  }
}

.errMsg {
  color: red;
}

.admin-dashboard {
  .discount-body {
    input,
    div {
      font-size: 12px;
    }
  }
}

.tab2,
.tab4,
.tab5 {
  thead {
    tr:first-child th {
      width: 10px;
    }
  }

  tbody {
    tr:first-child td {
      width: 10px;
    }
  }
}

.header {
  background-color: white;
  width: 100%;

  .logos-container {
    padding: 0.4rem 0;
    margin-left: 2.5rem;

    .solve-logo-container {
      height: 5rem;

      img {
        max-height: 100%;
      }
    }

    .insurecomp-logo-container {
      height: 2.47rem;
      margin-top: -1rem;

      img {
        max-height: 100%;
      }
    }
  }
}

.stat-2-1 {
  margin-right: 2.5rem;
  word-break: break-word;

  span {
    width: max-content;
    font-weight: bolder;
    font-size: 1rem;
  }

  img {
    height: 5.5rem;
    max-height: 100%;
    max-width: 100%;
  }
}

.upload-docs-action {
  border-radius: 7.2px;
  background-color: #a33336;
  border-color: #a33336;
  color: white;
  font-size: 1rem;
}

.upload-docs-action:hover {
  background-color: #a33336 !important;
  border-color: #a33336;
}

.alignbtn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.newquote-mainbody {
  // font-family: "Rokkitt", serif;
  //global styles for quote main body
  background-color: #ebebeb;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  .forSmall {
    display: none;
  }

  .newquote-content {
    //global styling for everything in the light blue background
    // padding-left: 1.5rem;
    // padding-right: 1.6rem;
    // width: 0%;
    // margin-top: 1rem;
    padding-bottom: 2rem;
    background-color: #ebebeb;
    padding-left: 0;

    .emodDate,
    form select,
    form input[type="text"],
    form input[type="email"],
    form input[type="password"],
    form input[type="date"],
    form textarea {
      width: 100%;
      // font-size: 0.85em;
      border-radius: 4px;
      border: 1px solid white;
      background: white;
      color: black; //#2b2d31;
      font-weight: "normal";
    }

    input[type="checkbox"] {
      height: 16px !important;
      width: 16px;
      border-radius: 4px;
      border: solid 1px #979797;
      background-color: #ffffff;
    }

    //styling of the headers and section-headers:
    // this is styling for all the different stages
    // for changing any heading, please refer to this section only
    .formTitle {
      color: white;
      // font-family: "Rubik", sans-serif;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 1.38;

      &.location-details-heading {
        font-weight: 400;
        font-size: 1.2rem;
      }

      &.compName {
        // font-family: Overpass;
        font-size: 20px;
        font-weight: 600;
        color: #2c2e33;
        // padding-left: 4rem;
      }

      &.title2 {
        width: 70%;
        display: inline-block;
      }

      &.title3 {
        font-size: 20px;
        font-weight: normal;
      }
    }

    .addressRate,
    .aggregate-heading,
    .stateAggName {
      // font-family: Overpass;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2b2d31;
    }

    //end of heading/subheading styles
    //styling of buttons:
    .transpButton {
      background-color: transparent;
      border-color: transparent;
    }

    .btnView {
      width: 210px;
      height: 40px;
      border-radius: 5px;
      background-color: #1463ac;
      margin-right: 1rem;
      margin-top: 2rem;
      // font-size: 14px;
      font-weight: 800;
    }

    .proceed-bind,
    .upload-docs {
      border-radius: 7.2px;
      background-color: #ffd13f;
      border-color: #eea236;
      // background-image: linear-gradient(to right, #10c03e, #0eaf84);
      // box-shadow: none;
      box-shadow: 2px 4px 12px #eea236;
      // border-color: transparent;
      color: white;
      font-size: 1rem;
      font-weight: 800;
    }

    .btnSubmits {
      // margin-top: 2rem;
      border-radius: 25px;
      background-color: #008dda;
      border-color: #008dda;
      // box-shadow: 2px 4px 12px #008DDA;
      color: white;
      font-size: 1rem;
      font-weight: 800;
      /* The above code is written in SCSS (Sassy CSS) syntax. It is setting the width of an element to
     20%. */
      // width: 20%;
    }

    // end of button styling
    //style for the progress bar at the top of every page
    // please refer to this section only for styling the progress bar
    //#3e9ccc
    .color-bar {
      margin-top: 1.5rem;
      margin-bottom: 25px;
      // margin-left: 1.2rem;
      width: 100%;
      height: 12px;
      border-radius: 8px;
      background-color: #8ab93f;
    }

    // end of progress bar styles
    //style for company profile tab
    #paymentQuote {
      span.errMsg {
        color: red;
        font-size: 11px;
      }
    }

    #compProfile {
      // background-image: url("../images/backimage1.png");
      // #peo-select:invalid {
      //   color: rgb(117, 117, 117);
      // }
      @media screen and (max-width: 992px) {
        .row {
          display: flex;
          flex-direction: column;
        }

        #phone-number,
        #entity-type,
        #years-in-business,
        #expected-start-date,
        #street-1,
        #zip-1,
        #city-1,
        #state-1,
        #street-2,
        #zip-2,
        #city-2,
        #state-2 {
          width: 100% !important;
        }
      }

      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      .class-desc-select {
        input {
          box-shadow: none !important;
        }
      }

      .pink-sign {
        font-weight: 800;
        margin-left: 15px;
        color: rgb(255, 255, 255);
        background-color: #2c2e33;
        border-radius: 50%;
        width: 29px;
        height: 29px;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      .company-profile-container,
      .question-list-container,
      .location-payroll-container,
      .emod-section-ques {
        margin-bottom: -15px;
        margin-left: 3rem;
        margin-right: 1rem;
      }

      .company-profile-container {
        margin-top: 3rem;
        margin-left: 4rem;
      }

      .location-payroll-container {
        #perCKdollar {
          width: 6rem;
        }

        #adminPercent {
          width: 5rem;
        }

        #requested-pricing-radio-yes,
        #requested-pricing-radio-no {
          margin-inline: 10px;
          align-self: center;
        }

        input[type="radio"] {
          width: 18px;
          height: 18px;
          display: grid;
          place-content: center;
          background-color: transparent;
          cursor: pointer;
        }

        input[type="radio"]::after {
          content: "";
          width: 0.65em;
          height: 0.65em;
          border-radius: 40%;
          transform: scale(0);
          transition: 2s transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
        }

        input[type="radio"]:checked::after {
          transform: scale(1);
          border: 1px solid #0763ee83;
          box-shadow: #0763ee83;
          transition: 2s transform ease-in-out;
        }
      }

      .question-list-container {
        td {
          padding-left: 0px;
        }
      }

      #sameAsPrimary-1 {
        box-shadow: none;
        border: 2px solid black !important;
        width: 14px !important;
      }

      .question-list-container {
        width: 80%;

        input[type="radio"] {
          width: 20px;
          height: 20px;
          display: grid;
          place-content: center;
          background-color: transparent;
          cursor: pointer;
        }

        input[type="radio"]:after {
          content: "";
          width: 0.65em;
          height: 0.65em;
          border-radius: 40%;
          transform: scale(0);
          transition: 2s transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
        }

        input[type="radio"]:checked:after {
          transform: scale(1);
          border: 1px solid #0763ee83;
          box-shadow: #0763ee83;
          transition: 2s transform ease-in-out;
        }

        // input[type="radio"]:after {
        //   width: 20px;
        //   height: 20px;
        //   border-radius: 20px;
        //   top: -3px;
        //   left: -1px;
        //   position: relative;
        //   background-color: #ffffff;
        //   content: "";
        //   display: inline-block;
        //   visibility: visible;
        //   border: 1px solid black;
        // }
        // input[type="radio"]:checked:after {
        //   width: 20px;
        //   height: 20px;
        //   border-radius: 20px;
        //   top: -3px;
        //   left: -1px;
        //   position: relative;
        //   background-color: #6e98e0;
        //   content: "";
        //   display: inline-block;
        //   visibility: visible;
        //   border: 1px solid black;
        // }
        // input[type="radio"]:after {
        //   transition: transform(0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75));
        //   transform: scale(0, 0);
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: rem(2);
        //   z-index: 1;
        //   width: 20px;
        //   height: 20px;
        //   background: #a04916;
        //   border-radius: 50%;
        // }
        // input[type="radio"]:checked:before {
        //   transform: scale(1, 1);
        // }
        // input[type="radio"]:after {
        //   content: "";
        //   position: absolute;
        //   top: rem(-4);
        //   left: rem(-2);
        //   width: 1rem;
        //   height: 1rem;
        //   background: #fff;
        //   border: 2px solid #f2f2f2;
        //   border-radius: 50%;
        // }
        .form-check-label {
          margin-left: 5px;
          font-size: 15px;
        }
      }

      .addLoc {
        margin-bottom: 18px;
      }

      .react-datepicker-wrapper {
        width: -webkit-fill-available;
        width: -moz-available;
        width: stretch;

        .react-datepicker__input-container {
          box-shadow: none;
          > input {
            padding-left: 0;
          }
        }
      }

      #healthQues,
      #otp {
        font-size: inherit;
      }

      .formTitle {
        margin-bottom: 34px;
        font-size: 21px;
        font-weight: bold;
      }

      .company-locations,
      .emod-ques-heading {
        margin-bottom: 30px;
      }

      .mandate {
        color: #919399;
      }

      small {
        color: #333333;
      }

      .form-group {
        margin-bottom: 38px;

        select,
        input {
          height: 35px;

          color: black;
          // font-size: 14px;
          padding-left: 5px;
          // box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
          box-shadow: 0.5px 1px 4px grey;
        }

        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // font-size: 11px;
          color: #333;
          margin-bottom: 0;

          &.mandatory::after {
            content: "*";
            padding-left: 2px;
          }
        }

        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }

      textarea {
        // padding-left: 5px;
        height: 123px;
      }

      #allLocations {
        margin-top: 6px;
      }

      .emod-ques {
        margin-top: 44px;
      }

      .address-delete {
        margin-top: 1.2rem;
      }

      .linkLike {
        position: relative;
        bottom: 20px;
      }

      #otpSendMsg,
      #otpSentMsg,
      #otpVerify,
      #verifySuc {
        display: none;
        font-size: 0.8em;
      }

      #otpSec,
      #resendOTP {
        display: none;
      }

      #emailStatus,
      #verifyStatus {
        margin-top: 1rem;
      }

      #timerOTP {
        font-size: 0.8rem;
      }

      .btn-link {
        padding-left: 0;
      }
    }

    #cmprRate {
      //style block for compare rate page/tab
      //styling for the background image
      background-image: url("../images/backimage2.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 100%;
      background-attachment: sticky;

      // .download-link-div {
      //   width: 20px;
      // }
      .formTitle {
        margin-bottom: 60px;
      }

      .title3 {
        margin-bottom: 25px;
      }

      .linkLike {
        margin-bottom: 37px;
      }

      .calcPremium {
        color: #919399;

        input {
          height: 36px;
          color: #2b2d31;
          font-size: 14px;
          padding-right: 5px;
        }
      }

      .rangeSlider {
        padding: 1.2rem;

        .row {
          // justify-content: space-around;
          margin: 0;
          width: 100%;
        }

        .premVal {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35%;
          height: 35px;
          border-radius: 20px;
          background-color: #2987de;
          font-size: 20px;
          font-weight: 800;
          color: #ffffff;

          span {
            position: relative;
            top: 2px;
          }
        }

        .barBG {
          margin-left: 1rem;
          margin-right: 1rem;
          margin-top: 1rem;
          width: 12px;
          height: 2px;
          background-color: #2c2e33;
        }
      }

      #attachSec {
        margin-top: 47px;

        .no-lossrun-affidavit-heading {
          color: #2b2d31;
          font-weight: "normal" !important;
          font-size: 14px;
        }

        .dropped-file {
          color: #4169ba;
        }

        .drop-zone {
          margin-top: 20px;
          margin-bottom: 44px;
          display: flex;
          background: white;
          opacity: 0.5;
          justify-content: center;
          align-items: center;
          height: 106px;
          width: 385px;
          border: 1px dashed black;

          span {
            color: #1463ac;
          }
        }

        .drop-info {
          margin-top: 20px;
        }
      }

      .grey-table-row {
        position: relative;
        right: 7px;
      }

      #claimTable {
        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }

        color: #919399;
        font-weight: "normal";

        // border-collapse: separate;
        // border-spacing: 20px 0px;
        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;

            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }

      .btnSubmits {
        margin-top: 30px;
        margin-bottom: 36px;
      }

      .form-group {
        select,
        input {
          height: 35px;
          color: #2b2d31;
          // font-size: 14px;
          padding-left: 5px;
        }

        label {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // font-size: 11px;
          color: #333;
          margin-bottom: 0;

          &.mandatory::after {
            content: "*";
            padding-left: 2px;
          }
        }

        p.errMsg {
          color: red;
          font-size: 11px;
        }
      }

      #claimTable {
        .form-group {
          margin-bottom: 2px;
        }
      }
    }

    #undrQues {
      // style for the background image:
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      //style for the unerwriter questions and additional questions table:
      .heading-row {
        justify-content: space-between;

        .compName {
          margin-top: 2px;
          color: #2c2e33;
          font-size: 20px;
          font-weight: 600;
        }
      }

      .table {
        --bs-table-bg: transparent;
        --bs-table-striped-bg: #ffffff;
      }

      .table-addnques,
      .table-undrques {
        margin-bottom: 3.5rem;
        background-color: #ebebeb;

        thead {
          color: #2c2e33;
          font-size: 1.2rem;

          tr {
            th {
              border: none;
              font-size: 16px;
              color: #a33336;
            }
          }
        }

        tbody {
          font-size: 14px;

          tr {
            td {
              vertical-align: middle;
              padding-top: 5px;
              padding-bottom: 5px;

              &.tableAns {
                .radio-btn-no {
                  margin-left: 2.5rem;
                  width: 18px;
                  height: 18px;
                }

                .radio-btn-yes {
                  margin-left: 1rem;
                  width: 18px;
                  height: 18px;
                }

                .radiobtn {
                  margin-left: 4px;
                  position: relative;
                  bottom: 4px;
                }

                .form-check-input:checked {
                  background-color: #a33336;
                  border-color: #a33336;
                }
              }

              &.tableQues {
                width: 60%;
              }

              &.tableRemarks {
                padding-right: 5px;

                input {
                  height: 32px;
                  border: 1px solid #a33336;
                }

                .hidden {
                  background-color: #742d4736;
                  // background-color: rgba(131, 165, 191, 0.2);
                  border: none;
                }
              }

              &.table-question {
                margin: 10px;
              }
            }

            &:nth-child(odd) {
              background-color: #ffffff;
            }
          }
        }
      }
    }

    .additional-questions {
      margin-bottom: 30px;
    }

    .table-undrques {
      margin-bottom: 2rem;
      background-color: #ebebeb;

      tbody {
        .trow1 {
          padding-top: 5px !important;
          padding-bottom: 5px !important;
        }
      }
    }

    .table-addnques {
      .tableAns {
        width: 18%;
      }
    }

    .btn-undrques {
      margin-bottom: 2rem;
    }

    #cmprQts,
    #underwriting-page {
      @include cmprQts;

      .quote-table {
        button {
          color: white;
          font-family: "MontserratSemiBold";
          border-radius: 12px;
        }

        .peo-btn {
          font-size: 1rem;
          width: 6rem;
          height: 2.5rem;
          box-shadow: 0px 5px 12px grey;
          background-color: #8ab93f;
          margin-right: 2.6rem;
        }

        .amtrust-btn,
        .key_risk-btn {
          font-size: 1rem;
          width: 6rem;
          height: 2.5rem;
          box-shadow: 0px 5px 12px grey;
          background-color: gray;
          margin-left: 2.5rem;
        }

        .quote-button-container {
          .close-btn,
          .export-btn {
            box-shadow: 0px 5px 12px grey;
            background-color: rgb(254, 174, 1);
          }

          .close-btn {
            width: 7rem;
          }
        }
      }

      .quote-table-box {
        box-shadow: 0.5px 1px 5px 3px grey;
      }

      .quote-table-box {
        background-color: white;
        padding: 1rem;

        .quote-button-container {
          display: flex;
          justify-content: center;
          // padding-inline: 300px;
        }

        .table-wrapper {
          max-height: 400px;
          border-top: 4px solid black;
        }

        table {
          border: 1px solid black;
        }

        th {
          background-color: #3e9ccc;
          color: white;
          text-align: center;
          vertical-align: middle;
          position: sticky;
          top: 0;
        }

        td {
          white-space: nowrap;
          // width: 50%;
          text-align: center;
          vertical-align: middle;
          max-width: 80px;
        }

        tr:nth-child(even) {
          background-color: rgba(128, 128, 128, 0.535);
        }

        tr th:first-child {
          border-left: 4px solid black;
        }

        tr th:last-child {
          border-right: 4px solid black;
        }

        tr td:first-child {
          border-left: 4px solid black;
        }

        tr td:last-child {
          border-right: 4px solid black;
        }

        tbody:last-child {
          border-bottom: 4px solid black;
        }

        tbody:first-child {
          margin-top: 0px;
        }

        tbody:before {
          content: "@";
          display: block;
          line-height: 0px;
          text-indent: -999999px;
        }
      }

      .emod-ques,
      .historical-claims {
        margin-top: 3rem;
      }

      .emod-ques {
        input[type="radio"] {
          width: 20px;
          height: 20px;
          display: grid;
          place-content: center;
          background-color: transparent;
          cursor: pointer;
        }

        input[type="radio"]:after {
          content: "";
          width: 0.65em;
          height: 0.65em;
          border-radius: 40%;
          transform: scale(0);
          transition: 2s transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
        }

        input[type="radio"]:checked:after {
          transform: scale(1);
          border: 1px solid #0763ee83;
          box-shadow: #0763ee83;
          transition: 2s transform ease-in-out;
        }

        .form-group {
          margin-bottom: 2px;

          label,
          input {
            font-size: 16px !important;
            color: black;
          }

          input {
            box-shadow: 0.5px 1px 4px grey;
          }
        }

        input[type="text"] {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #fff;
          background: #fff;
          box-shadow: 0.5px 1px 4px grey;
        }

        #dateRate1 {
          background: none;
          border: none;
        }
      }

      .grey-table-row {
        position: relative;
        right: 7px;
      }

      .historical-claims-container {
        padding: 1rem;
      }

      #claimTable {
        .form-group {
          margin-bottom: 2px;

          label,
          input {
            font-size: 16px !important;
            color: black;
          }

          input {
            box-shadow: 0.5px 1px 4px grey;
          }
        }

        input[type="text"] {
          width: 100%;
          border-radius: 4px;
          border: 1px solid #fff;
          background: #fff;
        }

        .form-group {
          select,
          input {
            height: 35px;
            color: #2b2d31;
            font-size: 14px;
            padding-left: 5px;
          }

          label {
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 11px;
            color: #333;
            margin-bottom: 0;

            &.mandatory::after {
              content: "*";
              padding-left: 2px;
            }
          }

          p.errMsg {
            color: red;
            font-size: 11px;
          }
        }

        input {
          height: 36px;
          margin-bottom: "none";
          color: #2b2d31;
          font-size: 14px;
        }

        color: #919399;
        font-weight: "normal";
        border-collapse: separate;

        // border-spacing: 20px 0px;
        .row-no-coverage {
          .no-coverage {
            width: 30%;
            margin-bottom: 20px;

            input {
              height: 12px;
              margin-bottom: 25px;
              margin-right: 5px;
            }
          }
        }
      }
    }

    //styling of review and pay page:
    #payBind {
      input,
      select {
        height: 40px;
      }

      .formTitle {
        margin-bottom: 37px;
      }

      #carrierName {
        font-size: 16px;
      }

      background-image: url("../images/backimage5.png");
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 95%;
      background-attachment: sticky;

      .row-formTitle {
        justify-content: space-between;

        .formTitle {
          margin-bottom: 0rem;
        }
      }

      .row-acord-preview-heading {
        justify-content: space-between;

        .col-acord-text {
          margin-left: 16px;
        }

        .col-acord-download {
          margin-left: 15px;
          padding-left: 36px;
        }

        #accordLink {
          color: #0973d5;
        }

        img {
          margin-right: 1rem;
        }
      }

      .row-acord-preview {
        margin-top: 1rem;
        justify-content: center;
        margin-bottom: 3.5rem;

        iframe {
          border: none;
          width: 78%;
          height: 30rem;
        }
      }

      .pdf-upload-placeholder {
        width: 78%;
        height: 30rem;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
        margin-bottom: 81px;

        .pdf-loader-gif {
          margin-left: 1.5rem;
        }
      }

      .row-tnc {
        margin-top: 1rem;
        font-size: 16px;
        color: #2c2e33;

        p {
          margin-top: 0.6rem;
        }

        .payPageItems2 {
          margin-left: 2rem;
        }

        .linkLike {
          margin-left: 0.5rem;
        }
      }
    }

    //styling for bind page
    #binderForm {
      .linkLike {
        margin-top: 60px;
        margin-bottom: 36px;
      }

      .btnGen {
        margin-bottom: 10px;
      }

      .col-sm-12 {
        .formTitle {
          margin-bottom: 1rem;
        }

        .row-binder-heading {
          padding-right: 10px;
          padding-left: 10px;
          justify-content: space-between;
        }

        .row-iframe {
          justify-content: center;
          width: 100%;

          .col-iframe {
            padding-left: 25px;
            padding-right: 25px;
            width: 100%;

            .acord {
              justify-content: flex-end;
              width: 95%;
              color: #0973d5;

              span {
                position: relative;
                top: 4px;
                margin-left: 0.5rem;
              }
            }

            .row-iframe-preview {
              width: 100%;
              justify-content: center;

              iframe {
                // margin-top: 2rem;
                border: none;
                width: 90%;
                height: 45rem;
              }
            }
          }
        }
      }
    }

    .class-desc-select {
      background-color: white;

      div {
        height: 35px;
        padding-top: 0;
        padding-bottom: 0;
      }

      .class-desc-select__indicator {
        align-items: center;
      }

      .class-desc-select__menu {
        height: fit-content;
        width: fit-content;
        max-width: 350px;
        min-width: -webkit-fill-available;
        min-width: -moz-available;

        .class-desc-select__menu-list {
          height: fit-content;
          max-height: 150px;

          .class-desc-select__option {
            display: flex;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: fit-content;
          }
        }
      }

      .class-desc-select__placeholder {
        margin: 0;
        position: unset;
        transform: none;
        height: fit-content;
      }

      .class-desc-select__control {
        // min-height: 35px;
        // border: 1px solid #ababab;
        // background: transparent;
        // border-radius: 4px;
        .class-desc-select__value-container {
          overflow: initial;

          .class-desc-select__single-value {
            height: fit-content;
          }
        }
      }
    }

    .locationName {
      span {
        font-size: 16px;
        font-weight: normal;
        color: #2b2d31;
      }
    }

    #extraInput {
      margin-top: 5px;
    }

    .check {
      margin-right: 0.3rem;
    }

    #newAddress {
      display: none;
      margin-left: 0;
    }

    .payPageItems {
      margin-left: 1rem;
    }

    .linkLike {
      position: relative;
      right: 0.55rem;
      // margin-top: 68px;
      // margin-bottom: 63px;
    }

    // // styl for binder
    .green {
      margin-left: 25%;
      font-size: 22px;
      font-weight: 600;
      color: #138f11;
      margin-bottom: 16px;

      p {
        margin: 0;
      }
    }

    .endForm {
      margin-left: 2rem;
    }
  }

  //styling for review and pay
  //paybind is the main container of review and pay page:
  // styles of side tab
  .newquote-sidebar {
    width: 18.5%;
    max-width: 50%;
    padding-left: 0.2rem;
    // background-color: #ffffff;
    background-image: url("../images/ies_backgroundimage1.jpg");
    // margin-top: 1rem;
    padding-bottom: 2rem;
    // margin-right: 1rem;
    // font-family: "Overpass", sans-serif;
    position: relative;

    font-size: 14px;
    .nav-item {
      outline: none;
      border-radius: none;

      .tick {
        margin-right: 0.08rem;
        margin-top: 1rem;
        float: right;
        margin-left: auto;
      }

      .nav-link {
        border: none;
        margin-bottom: 1rem;
        color: #2c2e33;
        // font-size: 16px;
        font-weight: 800;
        margin-left: 0.5rem;

        &.active {
          color: #0068c9;
          border: none;
        }

        .sidebar-stagename {
          font-size: 14px;
          margin-left: 1.2rem;
        }

        &.sidebar-heading {
          margin-top: 30px;
          color: #2c2e33;
        }

        .disabled {
          display: none;
        }
      }
    }
  }

  .newquote-sidebar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fce5c5;
    /* Orange color with 50% opacity */
    opacity: 0.9;
    // z-index: 10; /* Ensure the overlay is above the background image */
  }
}

#downloadable {
  width: 90%;
  margin: auto;
  padding-top: 20px;
  font-size: 14px;

  strong {
    font-size: 16px;
  }
}

#downloadable {
  .sectionHolder {
    padding: 20px;
    margin: 30px auto;
  }
}

.home-page {
  //styling of the new home page
  padding-top: 5rem !important;

  #banMain {
    img {
      width: 100%;
    }

    h2 {
      // font-family: "Merriweather", sans-serif;
      color: #2162ac;
      font-size: 50px;
      font-weight: 600;
      margin-top: 26px;
      margin-bottom: 16px;
    }

    h3 {
      color: #2f3237;
      font-size: 20px;
      line-height: 1.5;
      font-weight: normal;
      margin-bottom: 36px;
    }

    .btn {
      color: white;
      // font-family: "Rubik", sans-serif;
      font-size: 22px;
      font-weight: normal;
      border-radius: 7.2px;
      color: #fff;
      background-color: #ffd13f;
      border-color: #eea236;
      margin-bottom: 30px;
      box-shadow: 2px 4px 12px #eea236;
    }
  }
}

#itWorks {
  margin-right: 0px;
  margin-left: 0px;

  .row-3steps {
    // width: 100%;
    padding: 40px 0 40px 0;
    height: auto;
    background-color: #2162ac;

    // background-color: #2e3952;
    //3 steps logo
    .col-simplesteps {
      color: white;
      text-align: left;

      .simple-steps-wrapper {
        max-width: 300px;
      }

      h2 {
        text-align: left;
        font-size: 200px;
        margin-bottom: 0px;
        line-height: 1;
      }

      h3 {
        font-size: 44px;
        font-weight: 300;
        text-align: left;
      }

      img {
        margin: 1rem;
        position: relative;
        left: 15px;
        display: none;
      }

      .line-below-3 {
        width: 180px;
        height: 4px;
        opacity: 0.5;
        background-color: #ffffff;
        margin-bottom: 1rem;
        margin-top: 10px;
      }
    }

    //3-steps cards
    .col-simplesteps-cards {
      .card {
        width: 90%;
        color: white;
        background-color: transparent;

        .row {
          align-items: center;

          .col-card-image {
            height: auto;
            margin-right: 10px;

            .card-img {
              border: 0px !important;
              width: 97px;
              object-fit: contain;
              // img {
              //   margin-bottom: 35px;
              // }
            }
          }

          .col-card-text {
            height: auto;

            .card-body {
              text-align: left;

              .card-title {
                // font-family: "Rubik", sans-serif;
                font-size: 28px;
                font-weight: 500;
              }

              .card-text {
                margin-top: auto;
                // font-family: "Rubik", sans-serif;
                font-size: 20px;
                line-height: 1.5;
                font-weight: normal;
              }

              .learn-more {
                // font-family: Arial, Helvetica, sans-serif;
                color: #2ed359;
                font-size: 16px;
                font-weight: bold;
              }

              .learn-more-arrow {
                // font-size: 2rem;
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }

  .row-our-mission {
    color: white;
    height: 420px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.4),
      rgba(0, 153, 255, 0.4) 100%
    );
    background-image: url("../images/home-mission-background.jpg");

    .col-md-12 {
      padding: 80px 80px 80px 120px;

      h1 {
        position: relative;
        right: 13px;
        // font-family: "Merriweather", sans-serif;
        font-size: 100px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      h2 {
        // font-family: "Rubik", sans-serif;
        font-size: 24px;
        font-weight: normal;
        line-height: 1.5;
      }
    }
  }

  .row-everything-you-need {
    .col-md-12 {
      padding: 115px;

      h1 {
        color: #353a3f;
        // font-family: "Merriweather", sans-serif;
        font-weight: bold;
        font-size: 44px;
      }

      .card-row {
        .card {
          background-color: #f8f9fb;
          height: auto;
          margin: 20px;
          text-align: center;

          img {
            height: 80px;
            width: auto;
            margin-top: 40px;
            margin-bottom: 30px;
          }

          .card-title {
            color: #33333c;
            // font-family: "Rubik", sans-serif;
            font-size: 20px;
            line-height: 1.3;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  .row-video {
    background-color: #e7f4ff;
    height: auto;
    justify-content: center;

    .col-md-12 {
      padding-left: 114px;
      padding-right: 114px;
      padding-top: 100px;

      h1 {
        color: #2c2e34;
        // font-family: "Merriweather", sans-serif;
        font-size: 44px;
        font-weight: bold;
        line-height: 1.27;
      }

      h2 {
        color: #2c2e34;
        font-weight: normal;
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 40px;
      }

      iframe {
        margin-bottom: 120px;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  // body {
  //   background-color: lightblue;
  // }
  // #footerMain {
  //   position: absolute;
  //   bottom: 0;
  // }
}

#footerMain {
  // font-family: "Rubik", sans-serif;
  padding-top: 24px;
  padding-bottom: 20px;
  color: white;
  height: 4.75rem;
  // background-color: #2e3952;
  background-color: #183650;
  padding-left: 100px;
  padding-right: 100px;

  .row-1 {
    div:nth-of-type(6) {
      margin-right: 10px;
    }

    justify-content: space-between;

    ul {
      padding-left: 2px;
      list-style: none;
      font-size: 16px;
    }

    .col-img {
      img {
        height: 75px;
        width: 130px;
      }
    }

    .col {
      a {
        color: white;

        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }
  }

  .t-and-c-row {
    // opacity: 0.6;
    font-size: 13px;
    line-height: 2.23;

    .col-sm-2 {
      a {
        color: white;
      }
    }

    .dull {
      opacity: 0.6;
    }

    .back-to-top {
      margin-right: 10px;
    }
  }
}

.quote-table {
  .head-row {
    align-items: center;
    justify-content: space-between;
  }

  .hr1 {
    color: #2c2e33;
    font-size: 26px;
    font-weight: 500;

    &.quote-table-company-name {
      // font-family: "Overpass", sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .hr2 {
    color: #2c2e33;
    width: 70%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    span {
      color: #5f636b;
    }
  }

  .hr3 {
    margin-bottom: 1.5rem;
    color: #2c2e33;
  }

  .body-row {
    margin-top: 2rem;
    justify-content: center;

    .table {
      margin-bottom: 7rem;
      position: relative;
      right: 1rem;
      border-collapse: separate;
      border-spacing: 25px 0px;

      thead {
        th {
          color: #2c2e33;
          position: relative;
          right: 0.6rem;
          font-size: 16px;
        }
      }

      tbody {
        font-size: 18px;

        td,
        th {
          width: 25%;
          border: 1px solid white;
          background-color: white;
          color: #2c2e33;
          font-size: 18px;
          font-weight: normal;
        }

        td {
          p {
            font-size: 22px;
            font-weight: 800;
            color: #1463ac;

            .best {
              font-size: 14px;
              margin-left: 1em;
              color: #038b00;
            }

            .higher {
              font-size: 14px;
              margin-left: 1rem;
              color: #2c2e33;
            }
          }

          input {
            margin: 0.5rem;
          }
        }
      }
    }
  }
}

#resDrop,
#workDrop,
#quotesDrop,
#profDrop {
  position: relative;
  display: none;
  padding: 0;
}

#resDrop .navDrop,
#quotesDrop .navDrop,
#workDrop .navDrop,
#profDrop .navDrop {
  background: #ffffff;
  position: absolute;
  z-index: 1;
  text-align: left;
  width: 180px;
  top: 10px;
  border-radius: 6px;
  box-shadow: 5px 5px 20px #888888;
}

#quotesDrop .navDrop {
  right: 0px;
  padding: 10px;
}

#profDrop .navDrop {
  width: 200px;
  right: 0px;
}

#profDrop hr {
  margin-top: 0;
  margin-bottom: 0;
}

#profDrop .navDrop li {
  display: inline-block;
  padding: 15px;
  color: #095d98;
  font-size: 1.5rem;
  font-style: initial;
}

#resDrop .navDrop li,
#quotesDrop .navDrop li,
#workDrop .navDrop li {
  display: block;
  color: #095d98;
  padding: 10px;
}

#Dropresources:hover #resDrop,
#Dropworks:hover #workDrop,
#Dropquotes:hover #quotesDrop,
#Dropprofile:hover #profDrop {
  display: block;
}

#Dropprofile:hover #profDrop {
  display: block;
}

#resDrop .navDrop img,
#workDrop .navDrop img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.tab-content {
  // height: 600px;
  min-height: calc(100vh - 9rem);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab-content-responsive {
  min-height: fit-content;
}

// .tab-content-login,
// .tab-content-compliance {
//   min-height: calc(100vh - 15.75rem);
// }
// .tab-content-quote {
//   min-height: calc(100vh - 9rem);
// }
#loginButton {
  margin-left: 1rem;
  margin-right: 1rem;
}

#formLogin {
  height: auto;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formChange {
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset {
  // min-height: 390px;
  width: 450px;
  background: #ffffff;
  margin: auto;
  position: relative;
  padding-top: 20px;
  transition: all 0.5s;
}

#formReset,
#formLogin,
#formChange {
  border: 1px solid #f9f9f9;
}

#formReset:hover,
#formLogin:hover,
#formChange:hover {
  box-shadow: 0 10px 15px #888888;
  transition: all 0.5s;
}

#lgin {
  /* padding: 150px 0; */
  transition: all 0.5s;
  margin: auto;
}

#chngpwd,
#fgtpwd {
  margin: auto;
}

.forSmall {
  display: none;
}

#loader,
#new-loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
  z-index: 3000;
  top: 0;
  text-align: center;
  display: none;
}

#loader img,
#new-loader img {
  position: relative;
  width: 80px;
  top: 40%;
  margin: auto;
}

#new-loader {
  display: block;
}

#formReset,
#formChange,
#formLogin {
  // margin-top: -16.5px;
  margin-bottom: 16px;

  .formTitle {
    font-size: 20px;
  }

  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 20px;

  form {
    // margin-top: 10px;
    padding: 0px 20px 20px 20px;
  }

  form input[type="password"],
  form input[type="email"],
  form input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  button {
    margin: auto;
    height: 42px;
    width: 130px;
    border-radius: 30px;
    // background-image: linear-gradient(to right, #10c03e, #0eaf84);
    box-shadow: none;
    border-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 800;
    background-color: #a33336;
  }

  .formBottomLinks {
    // padding: 20px 20px 50px 20px;
    display: flex;
    padding: 20px;
    gap: 20%;

    a {
      color: #a33336;
      font-weight: bolder;
      text-decoration: none;
    }
  }

  .inptSideText {
    color: #5f636b;
  }
}

.formBottomLinks {
  padding: 20px;
}

.btnclassalign {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#changePassForm {
  input[type="text"] {
    width: 100%;
    font-size: 0.85em;
    border-radius: 4px;
    border: 1px solid white;
    background: white;
    color: #2b2d31;
    font-weight: "normal";
    height: 35px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

#quoteMain {
  @include cmprQts;
  background-color: #e2f2ff;

  #contentHolder {
    table {
      position: relative;
      right: 32px;
    }
  }
}

#resDrop,
#workDrop {
  .navDrop {
    padding: 0px;
    margin-left: -4rem;
    margin-top: 3rem;

    li {
      color: #0068c9;
      font-size: 13px;

      img {
        margin-left: 0px;
        margin-right: 8px;
        padding-left: 0px;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.cmprQts-buttons {
  margin-bottom: 10px;
  text-align: center !important;
  // margin-left: 14rem;
}

.btnGen {
  margin-bottom: 36px;
}

#uwPortal {
  .fixedHeader {
    thead > tr > th {
      position: sticky;
      top: -1px;
      z-index: 3;
      background-color: white;
    }

    tbody > tr > td {
      vertical-align: middle;
    }
  }

  display: flex;
  height: calc(100vh - 5.7rem);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  .btn-sm {
    min-width: 60px;
    margin-bottom: 5px;
  }

  .custom-control-label::before {
    color: #fff;
    background-color: #e97d88;
    border-color: #e97d88;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #28a745;
    background-color: #28a745;
  }

  .custom-switch .custom-control-label::after {
    background-color: #fff;
  }
}

h5 {
  color: #a33336;
  font-weight: 900;
}

.customDropZone {
  h5 {
    font-size: 16px;
  }

  @media only screen and (max-width: 1232px) {
    h5 {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 991px) {
    margin-top: 15px;
  }
}

.modal-full {
  width: 95%;
  max-width: none !important;
  height: 95%;

  iframe {
    min-height: 80vh;
  }
}

@media only screen and (max-width: 740px) {
  .new-navbar {
    .boxed-logo {
      height: 100px;
      padding: 15px;
    }
  }
}

@media only screen and (max-width: 580px) {
  .new-navbar {
    .boxed-logo {
      height: 75px;
      padding: 11px;
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .new-navbar {
    .boxed-logo {
      height: 60px !important;
      padding: 8px;
      margin-top: 35px;
    }
  }
  #compPro,
  #undrQues,
  #viewProposal {
    margin-bottom: 28px !important;
    .acordParseform {
      margin-left: 1rem;
      margin-top: 3rem;

      .acord_input {
        font-size: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: white;
      }

      .acordSubmitButton {
        font-size: 14px;
        background-color: #a33336;
        border-color: #a33336;
        margin-left: 1rem;
      }
    }

    .compProHeading {
      margin-left: 0.5rem;
      color: #7a7878;
      font-size: 15px;
    }
  }
}

@media only screen and (max-width: 420px) {
  .new-navbar {
    .boxed-logo {
      margin: 5px 10px;
      margin-left: 20px;
      margin-top: 35px;
    }
  }
  #compPro,
  #undrQues,
  #viewProposal {
    margin-bottom: 28px !important;
    .acordParseform {
      margin-left: 1rem;
      margin-top: 3rem;

      .acord_input {
        font-size: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: white;
      }

      .acordSubmitButton {
        font-size: 14px;
        background-color: #a33336;
        border-color: #a33336;
        margin-left: 1rem;
      }
    }

    .compProHeading {
      margin-left: 0.5rem;
      color: #7a7878;
      font-size: 15px;
    }
  }
}

@media only screen and (max-width: 380px) {
  .new-navbar {
    .pride-logo {
      margin-left: 1rem;
    }

    .insurecomp-logo {
      margin-right: 1rem;
    }

    .new-logo {
      height: 3rem;
    }
  }
  #compPro,
  #undrQues,
  #viewProposal {
    margin-bottom: 28px !important;
    .acordParseform {
      margin-left: 1rem;
      margin-top: 3rem;

      .acord_input {
        font-size: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: white;
      }

      .acordSubmitButton {
        font-size: 14px;
        background-color: #a33336;
        border-color: #a33336;
        margin-left: 1rem;
      }
    }

    .compProHeading {
      margin-left: 0.5rem;
      color: #7a7878;
      font-size: 15px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .new-navbar {
    .boxed-logo {
      height: 50px !important;
      padding: 5px;
      margin: 5px 5px;
      margin-top: 40px;
      margin-left: 15px;
    }
  }
  #compPro,
  #undrQues,
  #viewProposal {
    margin-bottom: 28px !important;
    .acordParseform {
      margin-left: 1rem;
      margin-top: 3rem;

      .acord_input {
        font-size: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: white;
      }

      .acordSubmitButton {
        font-size: 14px;
        background-color: #a33336;
        border-color: #a33336;
        margin-left: 1rem;
      }
    }

    .compProHeading {
      margin-left: 0.5rem;
      color: #7a7878;
      font-size: 15px;
    }
  }
}

@media only screen and (max-width: 340px) {
  .new-navbar {
    .pride-logo {
      margin-left: 1rem;
    }

    .insurecomp-logo {
      margin-right: 1rem;
    }

    .new-logo {
      height: 2.5rem;
    }
  }
  #compPro,
  #undrQues,
  #viewProposal {
    margin-bottom: 28px !important;
    .acordParseform {
      margin-left: 1rem;
      margin-top: 3rem;

      .acord_input {
        font-size: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: white;
      }

      .acordSubmitButton {
        font-size: 14px;
        background-color: #a33336;
        border-color: #a33336;
        margin-left: 1rem;
      }
    }

    .compProHeading {
      margin-left: 0.5rem;
      color: #7a7878;
      font-size: 15px;
    }
  }
}

.dashboard-admin {
  .new-navbar {
    background-color: white;
    border-bottom-style: groove;
  }

  .tabs {
    .MuiTableSortLabel-root.MuiTableSortLabel-active {
      color: white;

      .MuiTableSortLabel-icon {
        color: white !important;
      }
    }

    .nav-item {
      a,
      .active {
        color: black;
      }

      .active {
        background-color: #ffd13f;
      }
    }
  }
}

.dropZonesContainer {
  // padding: 0 25px;
  .customDropZone {
    .dropzone {
      text-align: center;
      padding: 20px;
      // border: 2px dashed #363333;
      background-color: #df9195;
      border-radius: 15px;

      // color: #8a5656;
      .dropZoneText {
        cursor: pointer;
      }
    }

    li {
      background: none;

      .fw-bold {
        cursor: pointer;
      }
    }
  }
}

.dashboard-link-mobile {
  margin-left: 3rem !important;
  font-weight: bolder !important;
}

// .navbar-font{
//   font-size: 1.5rem !important;
// }
.popup {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  top: 0;
  text-align: center;
  display: none;
  background: rgba(0, 0, 0, 0.6);
}

.popup-container {
  background-color: #ebebeb;
  border-radius: 15px;
  box-shadow: 2px 4px 12px black;
  position: absolute;
  width: 500px;
  left: 50%;
  top: 50%;
  padding: 20px;
  color: black;
  transform: translate(-50%, -50%);

  .header-text {
    font-weight: bold;
  }

  max-height: 90vh !important;
  overflow: auto !important;
}

.error-pop-up-container {
  background-color: #23b4e8;
  border-radius: 15px;
  box-shadow: 2px 4px 12px black;
  position: fixed;
  width: 500px;
  left: 50%;
  top: 50%;
  padding: 20px;
  color: white;
  transform: translate(-50%, -50%);

  .header-text {
    font-weight: bold;
  }

  max-height: 90vh !important;
  overflow: auto !important;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  text-transform: uppercase;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.class-desc-select__menu {
  width: 60vw !important;
  max-width: none !important;
}

.class-desc-select__option {
  width: auto !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.pink-header {
  color: #a33336;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  padding-bottom: 35px;
}

#company-name-link:hover {
  text-decoration: underline;
}

.workcomp-line {
  background-color: #497fbc;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 40px;

  p {
    font-size: 60px;
    text-align: center;
    margin-top: 20px;
    font-family: "MontserratSemiBold";
    line-height: 1.5;
    font-weight: 700;
  }
}

.dashboard-container {
  .company-name {
    cursor: pointer;
    color: cornflowerblue;
  }

  .company-name:hover {
    text-decoration: underline;
    color: rgba(100, 148, 237, 0.575);
  }
}

.multi-select-container {
  button {
    width: 16rem !important;
  }
}

.custom-left-right-switch {
  font-size: 15px;
  font-weight: 900;
  // height: 45px;
  // cursor: pointer !important;
}

.peo-header-styling {
  display: flex;
  justify-content: center;
  align-items: center;
}

#rater-eff-date {
  input {
    height: 35px;
    border: none;
  }
}

.newrates-container {
  overflow-x: hidden;

  .rater-action-buttons {
    column-gap: 3.5rem;
  }

  .information {
    margin-top: 3rem;
    font-size: 0.875rem;

    .outer-list {
      padding: 0;

      .inner-list {
        padding: 0 0 0 1.5rem;
      }
    }
  }
}

.navwidth {
  width: 100% !important;
}

.navbarcoloring {
  background-color: #ffffff;
}

.nav_style {
  font-size: 16px !important;
  align-items: start !important;
  height: auto !important;
  background-color: white !important;
}

.nav_style_2 {
  margin-top: 30px;
  background-color: transparent !important;
}

.nav_style_3 {
  // margin-top: 30px;
  background-color: transparent !important;
}

.historical_no_claims {
  gap: 5px;
}

@media only screen and (max-width: 509px) {
  .nav_fontsize {
    font-size: 14px !important;
    align-items: start !important;
  }

  .carrieroptionselect {
    justify-content: inherit !important;
    font-size: 20px !important;
    padding-bottom: 20px !important;
  }

  .keyriskcenter {
    text-align: center !important;
    padding-top: 0px !important;
  }

  .uploadlossruns {
    margin-bottom: 43px !important;
  }

  .historical_no_claims {
    margin-bottom: -46px !important;
  }

  #formLogin {
    height: auto;
    width: 400px;
    position: relative;
    padding-top: 20px;
    transition: all 0.5s;
  }
}

@media only screen and (max-width: 453px) {
  .nav_fontsize {
    font-size: 12px !important;
    align-items: start !important;
  }

  .historical-claims-container {
    padding: 0rem !important;
  }

  #formLogin {
    height: auto;
    width: 370px;
    position: relative;
    padding-top: 20px;
    transition: all 0.5s;
  }
}

@media only screen and (max-width: 400px) {
  #formLogin {
    height: auto;
    width: 330px;
    position: relative;
    padding-top: 20px;
    transition: all 0.5s;
  }

  .nav_fontsize {
    font-size: 11px !important;
    align-items: start !important;
  }
}

@media only screen and (max-width: 372px) {
  .nav_fontsize {
    font-size: 10px !important;
    align-items: start !important;
  }

  #formLogin {
    height: auto;
    width: 280px;
    position: relative;
    padding-top: 20px;
    transition: all 0.5s;
  }
}

@media only screen and (max-width: 348px) {
  .nav_fontsize {
    font-size: 9px !important;
    align-items: start !important;
  }
}

@media only screen and (max-width: 323px) {
  .nav_fontsize {
    font-size: 8px !important;
    align-items: start !important;
  }
}

@media only screen and (max-width: 700px) {
  .bringcolumn {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 19px !important;
    padding-top: 10px 0px !important;
  }

  .responsivecolumn {
    display: contents !important;
  }

  .Startnewquotefont {
    font-size: 1.5rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .Landing__page__image {
    object-fit: contain;
    height: 440px;
  }

  .imagesizelogo {
    height: 140px !important;
  }

  .imagesizelogoicomp {
    height: 70px !important;
  }
}

@media only screen and (min-width: 1250px) {
  .Landing__page__image {
    object-fit: contain;
    height: 450px;
  }

  .imagesizelogo {
    height: 150px !important;
  }

  .imagesizelogoicomp {
    height: 75px !important;
  }
}

@media only screen and (min-width: 1330px) {
  .Landing__page__image {
    object-fit: contain;
    height: 460px;
  }

  .imagesizelogo {
    height: 100px !important;
  }

  .imagesizelogoicomp {
    height: 70px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .Landing__page__image {
    object-fit: contain;
    height: 470px;
  }
}

@media only screen and (max-width: 1500px) {
  .Landing__page__image {
    object-fit: contain;
    height: 490px;
  }
}

@media only screen and (max-width: 1600px) {
  .Landing__page__image {
    object-fit: contain;
    height: 510px;
  }
}

@media only screen and (min-width: 1700px) {
  .Landing__page__image {
    object-fit: contain;
    height: 530px;
  }

  .imagesizelogo {
    height: 170px !important;
  }

  .imagesizelogoicomp {
    height: 90px !important;
  }
}

.Landing__page__image {
  object-fit: contain;
  height: 550px;
}

@media only screen and (max-width: 700px) {
  .imagesizelogo {
    height: 120px !important;
  }

  .imagesizelogoicomp {
    height: 60px !important;
  }
}

@media only screen and (max-width: 374px) {
  .imagesizelogo {
    height: 85px !important;
    margin-left: 10px !important;
    margin-top: 10px;
  }

  .imagesizelogoicomp {
    height: 60px !important;
    margin-right: 0px !important;
  }

  .Landing__page__image {
    height: 250px !important;
  }
}

@media only screen and (max-width: 700px) {
  .finallyquestionwidth {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) {
  #carrier,
  #Annaulprem,
  #claims,
  #totalincurred {
    display: inline-block;
    max-width: 50%;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1010px) {
  .workcomp-line-paragraph {
    text-align: center !important;
    font-size: 40px !important;
  }
}

@media only screen and (max-width: 700px) {
  .workcomp-line-paragraph {
    text-align: center !important;
    font-size: 30px !important;
  }
}

@media only screen and (max-width: 500px) {
  .workcomp-line-paragraph {
    text-align: center !important;
    font-size: 20px !important;
  }
}

#datepicker-startDate {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media only screen and (max-width: 500px) {
  #datepicker-startDate {
    margin-top: 51px;
  }
}

@media only screen and (max-width: 900px) {
  .rater-action-buttons {
    gap: 1.7rem;
  }

  .listofrates {
    display: block;
  }

  .statework_oneline {
    display: flex;
    justify-content: flex-end;
  }

  .selectprogramone {
    display: flex;
    align-items: flex-end;
    align-content: center;
  }

  .statework_two {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-end;
    padding-top: 20px;
    align-content: center;
  }

  .peorates {
    display: flex !important;
    padding: 10px !important;
  }

  // .manrate_netrate {
  //     display: flex;
  //     flex-direction: column;
  // }
  .stateandworkes,
  .statework {
    display: flex;
    flex-direction: column;
  }

  // .namesofpeo {
  //     display: flex;
  //     flex-direction: column;
  // }
  .selectprogram {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 10px !important;
  }

  // .listofPeo {
  //     display: inline-block !important;
  //     padding: 0px;
  // }
  // .makingdisplaynone {
  //     display: none;
  // }
  .mobileviewmin_net {
    display: flex;
    flex-direction: row !important;
    align-items: flex-end;
  }

  .paddingcolumn {
    padding-top: 15px;
    display: block;
  }

  .downloadbutton {
    display: flex;
    justify-content: center !important;
  }

  .addingoverflow {
    height: auto;
    overflow: auto;
    max-height: 310px;
  }

  .downloadbuttonmargin {
    margin: 0px !important;
    padding-top: 64px !important;
  }

  .buttontoright {
    display: flex;
    justify-content: flex-end;
  }

  // .statetoleft,
  // .workerscomptoleft {
  //   display: flex;
  //   justify-content: end;
  // }
  .statetoleft {
    display: flex;
    justify-content: center;
    padding-left: 4rem !important;
  }

  .selcetprogramtoright {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1250px) {
  .rater-action-buttons {
    gap: 1.7rem;
  }

  .listofrates {
    display: block;
  }

  .statework_oneline {
    display: flex;
    justify-content: flex-end;
  }

  .selectprogramone {
    display: flex;
    align-items: flex-end;
    align-content: center;
  }

  .statework_two {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    padding-top: 20px;
    align-content: center;
  }

  .peorates {
    display: flex !important;
    padding: 10px !important;
  }

  // .manrate_netrate {
  //     display: flex;
  //     flex-direction: column;
  // }
  .stateandworkes,
  .statework {
    display: flex;
    flex-direction: column;
  }

  // .namesofpeo {
  //     display: flex;
  //     flex-direction: column;
  // }
  .selectprogram {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px !important;
  }

  // .listofPeo {
  //     display: inline-block !important;
  //     padding: 0px;
  // }
  // .makingdisplaynone {
  //     display: none;
  // }
  .mobileviewmin_net {
    display: flex;
    flex-direction: row !important;
    align-items: flex-end;
  }

  .paddingcolumn {
    padding-top: 15px;
    display: block;
  }

  .downloadbutton {
    display: flex;
    justify-content: center !important;
  }

  .addingoverflow {
    height: auto;
    overflow: auto;
    max-height: 310px;
  }

  .downloadbuttonmargin {
    margin: 0px !important;
    padding-top: 64px !important;
  }

  .buttontoright {
    display: flex;
    justify-content: flex-end;
  }

  // .statetoleft,
  .workerscomptoleft {
    padding-left: 2.5rem !important;
  }

  .statetoleft {
    display: flex;
    justify-content: center;
    padding-left: 4rem !important;
  }

  .selcetprogramtoright {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 700px) {
  .selectprogram {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px !important;
  }

  .autowidthing {
    width: 24rem !important;
  }

  .datewidth {
    width: 24rem !important;
  }

  .multi-select-container button {
    width: 24rem !important;
  }

  .addingpadding {
    padding-bottom: 24px !important;
  }

  .padding_to_netrate {
    padding-bottom: 1rem !important;
  }
}

@media only screen and (max-width: 645px) {
  .datewidth {
    width: 21rem !important;
  }
}

@media only screen and (max-width: 580px) {
  .datewidth {
    width: 19rem !important;
  }

  .listofdatatodisplay {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 530px) {
  .datewidth {
    width: 18rem !important;
  }
}

@media only screen and (max-width: 490px) {
  .datewidth {
    width: 17rem !important;
  }

  .listofdatatodisplay {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 460px) {
  .datewidth {
    width: 16rem !important;
  }
}

@media only screen and (max-width: 420px) {
  .datewidth {
    width: 14rem !important;
  }
}

@media only screen and (max-width: 400px) {
  .datewidth {
    width: 13rem !important;
  }
}

@media only screen and (max-width: 390px) {
  .datewidth {
    width: 12rem !important;
  }
}

@media only screen and (max-width: 350px) {
  .datewidth {
    width: 10rem !important;
  }
}

.selectprogramone {
  display: flex;
  align-items: center;
}

.selcetprogramtoright {
  padding: 12px;
}

.mobileviewmin_net {
  display: flex;
}

.companyname_margin {
  margin-top: -10px !important;
}

.companyrow_margin {
  margin-top: -15px !important;
}

.feinrow_margin {
  margin-top: -20px !important;
}

.Phone_margin {
  margin-top: -15px !important;
}

.Phone_margin {
  margin-top: -12px;
}

.primary_margin {
  margin-top: -13px !important;
}

.street_margin {
  margin-top: -5px !important;
}

.mailing_margin {
  margin-top: -12px;
}

.mail_street_margin {
  margin-top: -5px;
}

.loc1street_margin {
  margin-top: -10px !important;
  color: #a33336;
  font-weight: bold;
}

.Workerscomp_margin {
  margin-top: -20px !important;
}

.add_classcode_margin {
  margin-top: -15px !important;
}

@media only screen and (max-width: 1286px) {
  .navbar_padding {
    padding: 0px 35px !important;
  }
}

@media only screen and (max-width: 1216px) {
  .navbar_padding {
    padding: 0px 30px !important;
  }
}

.navcontent {
  gap: 4rem;
}

@media only screen and (max-width: 1166px) {
  .carrier__list {
    width: 300px !important;
  }

  .navbar_padding {
    padding: 0px 25px !important;
  }

  .navcontent {
    font-size: 14px !important;
    gap: 3rem;
  }
}

@media only screen and (max-width: 1053px) {
  .carrier__list {
    width: 280px !important;
  }

  .navbar_padding {
    padding: 0px 20px !important;
  }

  .navcontent {
    font-size: 13px !important;
    gap: 2.8rem;
  }
}

@media only screen and (max-width: 1000px) {
  .carrier__list {
    width: 240px !important;
  }

  .navbar_padding {
    padding: 0px 15px !important;
  }

  .navcontent {
    font-size: 12px !important;
    gap: 2.8rem;
  }
}

@media only screen and (max-width: 1000px) {
  .navcontent {
    font-size: 12px !important;
    gap: 2.5rem;
  }
}

.navbar_padding {
  padding: 0px 42px;
}

.headings {
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #a33336;
  width: 15rem;
  min-width: fit-content;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  font-size: 18px;
}

#compPro,
#undrQues,
#viewProposal {
  margin-bottom: 28px !important;
  .acordParseform {
    margin-left: 4rem;
    margin-top: 3rem;

    .acord_input {
      font-size: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: white;
    }

    .acordSubmitButton {
      font-size: 14px;
      background-color: #a33336;
      border-color: #a33336;
      margin-left: 1rem;
    }
  }

  .compProHeading {
    margin-left: 0.5rem;
    color: #7a7878;
    font-size: 15px;
  }
}
#priceIndicationPreview {
  margin-bottom: 28px !important;
}

//Navbar
.dropdown:hover .dropdown-menu {
  display: block;
}

#navbarMain {
  a {
    text-decoration: none;
  }
}

nav {
  background-color: #91cfa1;
  height: 50px;
  padding: 0 16px;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  /* fade in checked menu */
}

nav .m-menu__checkbox {
  display: none;
}

nav label.m-menu__toggle {
  cursor: pointer;
}

nav .m-menu {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 450px;
  width: calc(100vw - 30px);
  // height: 100vh;
  height: 100%;
  // height: calc(var(--vh, 1vh) * 100);
  -moz-transform: translate3d(-450px, 0, 0);
  -o-transform: translate3d(-450px, 0, 0);
  -ms-transform: translate3d(-450px, 0, 0);
  -webkit-transform: translate3d(-450px, 0, 0);
  transform: translate3d(-450px, 0, 0);
  -moz-transition: transform 0.35s;
  -o-transition: transform 0.35s;
  -webkit-transition: transform 0.35s;
  transition: transform 0.35s;
  z-index: 12;
  overflow: hidden;
  background-color: #fff;
}

nav .m-menu__overlay {
  background-color: rgba(103, 103, 103, 0.5);
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: 12;
  display: none;
}

nav .m-menu__header {
  padding-left: 0px;
  height: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

nav .m-menu__header span {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

nav .m-menu .m-menu {
  -moz-transform: translate3d(480px, 0, 0);
  -o-transform: translate3d(480px, 0, 0);
  -ms-transform: translate3d(480px, 0, 0);
  -webkit-transform: translate3d(480px, 0, 0);
  transform: translate3d(480px, 0, 0);
}

// nav .m-menu ul {
//   height: 100%;
//   overflow-y: auto;
// }

// nav .m-menu ul li a,
// .m-menu ul li label {
//   display: block;
//   text-align: left;
//   padding: 0 15px;
//   line-height: 47px;
//   text-decoration: none;
//   color: #333;
//   cursor: pointer;
//   font-size: 1rem;
//   border-bottom: 1px solid #e8e8e8;
//   position: relative;
// }

// nav .m-menu ul li label.a-label__chevron::after {
//   content: "";
//   position: absolute;
//   display: inline-block;
//   height: 10px;
//   width: 10px;
//   border-color: #333;
//   border-style: solid;
//   border-width: 1px 1px 0 0;
//   -moz-transform: rotate(45deg);
//   -o-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   -webkit-transform: rotate(45deg);
//   transform: rotate(45deg);
//   top: 50%;
//   margin-top: -5px;
//   right: 16px;
// }

// nav .m-menu ul li .-invisible {
//   border-bottom: 0;
// }

nav .m-menu .m-menu label.m-menu__toggle {
  display: flex;
  border-bottom: 0;
  padding: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

nav .m-menu__checkbox:checked ~ .m-menu__overlay {
  display: block;
}

nav .m-menu__checkbox:checked ~ .m-menu {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.wrapper {
  width: 480px;
  height: 667px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
}

.no-bullets {
  list-style-type: none;
  padding: 0px 10px;
  margin: 0;
}

.no-bullets-align {
  list-style-type: none;
  padding: 0px 10px;
  margin: 0;
}

@media only screen and (max-width: 460px) {
  .wrapper {
    width: 400px;
    height: 667px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
  }
}

@media only screen and (max-width: 900px) {
  .navcontent {
    display: flex !important;
    flex-direction: column !important;
    gap: 2rem;
    font-weight: 900;
    padding-left: 20px;
  }

  .newquote-mainbody {
    .newquote-sidebar {
      width: 100% !important;
      max-width: 100%;
      padding-bottom: 0rem !important;
      margin: 0px !important;
      background-image: none;
      position: static;
    }

    .newquote-sidebar::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      opacity: 0.9;
      // z-index: 10; /* Ensure the overlay is above the background image */
    }
  }

  .m-menu__toggle {
    padding-left: 25px;
  }

  body {
    font-size: 15px;
  }

  .address-delete {
    display: grid !important;
    padding-bottom: 19px !important;
    width: auto !important;
  }

  .historical-claims-container {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

@media (min-width: 900px) and (max-width: 1024px) {
  .newquote-mainbody {
    .newquote-sidebar {
      width: 30%;
    }
  }
}

@media only screen and (max-width: 530px) {
  .m-menu {
    max-width: 400px !important;
  }

  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 450px) {
  .wrapper {
    width: 330px;
    height: 667px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
  }

  .m-menu {
    max-width: 300px !important;
  }
}

@media only screen and (max-width: 320px) {
  .m-menu {
    max-width: 250px !important;
  }

  body {
    font-size: 13px;
  }
}

#viewProposal,
#cmprQts {
  > section {
    margin-bottom: 45px;
  }

  .sectionContent {
    font-size: 12px;
    padding: 0px 20px 0 20px;
  }

  .sectionContent1 {
    font-size: 12px;
  }

  .tableContainer {
    overflow-x: auto;
  }

  thead {
    th {
      background-color: rgb(223, 145, 149);
    }
  }

  font-size: 16px;

  .quotes-table {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(1) {
    padding: 0px 0px 2.5px 0px !important;
  }

  .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
    // width: 200px !important;
    box-shadow: none !important;
  }

  .MuiPaper-root {
    font-family: "MontserratSemiBold";
  }

  .MuiToolbar-regular {
    min-height: 0px !important;
  }

  .MuiPaper-elevation2 {
    box-shadow: none !important;
  }

  //body

  //head

  //border colore
  // .MuiTableHead-root {
  //   tr {
  //     border-bottom: 20px solid #e2f2ff;
  //   }
  // }
  //header
  //rows
  // .MuiTableBody-root {
  //   .MuiTableRow-root:nth-child(even) {
  //     background-color: #8eabd0 !important;
  //     color: white;
  //   }
  //   .MuiTableRow-root:nth-child(odd) {
  //     background-color: #e2f2ff !important;
  //     color: #606558;
  //   }
  //   .MuiTableRow-root:nth-last-child(-n + 1) {
  //     background-color: #c8aca9 !important;
  //     color: white;
  //   }
  // }

  .MuiTableBody-root {
    .MuiTableRow-root {
      background-color: #e2f2ff !important;
      color: #606558;
    }
  }

  .MuiTableCell-root {
    padding: 7px 0px 7px 0px !important;
    border: none;
  }

  .chekcbox-input {
    height: 15px !important;
    width: 15px !important;
    align-items: center;
    // margin-left: 5px;
  }

  .fontName-Size,
  .viewQuoteButton {
    font-size: 1.3rem;
  }

  // .MuiTableCell-head {
  //   // width: 200px !important;
  //   // padding: 20px 10px !important;
  // }
  .MuiTable-root {
    background-color: #e2f2fe;
  }

  .MuiTable-root .MuiTableHead-root {
    box-shadow: none !important;
    border-style: none !important;
  }

  .Saic-column {
    color: red !important;
  }
}

#underwriting-page {
  .quotes-table {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(1) {
    padding: 0px 0px 2.5px 0px !important;
  }

  .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
    // width: 200px !important;
    box-shadow: none !important;
  }

  .MuiPaper-root {
    font-family: "MontserratSemiBold";
  }

  .MuiToolbar-regular {
    min-height: 0px !important;
  }

  .MuiPaper-elevation2 {
    box-shadow: none !important;
  }

  //body

  //head

  //border colore
  // .MuiTableHead-root {
  //   tr {
  //     border-bottom: 20px solid #e2f2ff;
  //   }
  // }
  //header
  //rows
  .MuiTableBody-root {
    // .MuiTableRow-root:nth-child(even) {
    //   background-color: #8eabd0 !important;
    //   color: white;
    // }
    // .MuiTableRow-root:nth-child(odd) {
    //   background-color: #e2f2ff !important;
    //   color: #606558;
    // }
    .MuiTableRow-root {
      background-color: #e2f2ff !important;
      color: #606558;
    }

    // .MuiTableRow-root:nth-last-child(-n + 1) {
    //   background-color: #2f75b5 !important;
    //   color: white;
    // }
  }

  .MuiTableCell-root {
    padding: 7px 10px 7px 10px !important;
    border: none;
  }

  .chekcbox-input {
    height: 24px !important;
    width: 20px !important;
    align-items: center;
    margin-left: 5px;
  }

  .fontName-Size,
  .viewQuoteButton {
    font-size: 1.3rem;
  }

  .MuiTableCell-head {
    // width: 200px !important;
    // padding: 20px 10px !important;
  }

  .MuiTable-root {
    background-color: #e2f2fe;
  }

  .MuiTable-root .MuiTableHead-root {
    box-shadow: none !important;
    border-style: none !important;
  }

  .Saic-column {
    color: red !important;
  }
}

.dashboard-rater {
  .MuiTableSortLabel-active {
    color: white !important;
    font-weight: 700 !important;

    svg {
      color: white !important;
    }
  }
}

@media only screen and (max-width: 980px) {
  #riskprofile-table {
    font-size: 15px !important;
  }
}

.raterNameField {
  border-radius: 2px;
  border-width: 0;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.125);
  border-color: #cccccc;
  outline: currentcolor none medium !important;
  border: none;
}

#riskprofile-table {
  .dropdown__container {
    @media only screen and (max-width: 700px) {
      display: flex;
      flex-direction: column !important;
    }

    .dropdown {
      min-width: 200px;
    }
  }

  table {
    td {
      border: 1.3px solid white;
      text-align: right;
      font-family: "MontserratSemiBold";
      padding: 0.2rem !important;
    }

    th {
      font-family: "MontserratBold";
      text-align: right;
      border: 1.3px solid white !important;
      font-size: 1.1rem !important;
    }

    td:nth-child(1) {
      background-color: #d5d5d5;
    }

    .key-header {
      text-align: left;
    }

    .table-rows {
      .key {
        border-left: 0.5px solid grey;
        border-right: 0.5px solid grey;
        text-align: left;
      }

      .premium,
      .premium-mix {
        border-right: 0.5px solid grey;
      }

      .payroll,
      .premium,
      .premium-mix,
      .mix-rate,
      .wage-mix {
        background-color: white;
      }
    }

    .totals-row {
      background-color: #a33336;
      color: white;

      td {
        font-family: "MontserratSemiBold";
      }
    }

    .table-header {
      background-color: #df9195;
      color: white;
    }

    border-collapse: collapse;
    color: #292d2f;

    .Total-riskprofile {
      text-align: left;
      background-color: #a33336 !important;
    }
  }

  .eligibility-table {
    .table-rows {
      td:nth-child(1) {
        text-transform: capitalize;
      }
    }
  }

  .naics-table,
  .sic-table,
  .eligibility-table {
    .table-name {
      background-color: #a33336;
      text-align: center;
      vertical-align: middle;
      width: 12%;
    }

    .key,
    .key-header {
      width: 29%;
    }

    .payroll,
    .premium,
    .payroll-header,
    .premium-header {
      width: 13%;
    }
    .payroll,
    .premium,
    .premium-mix,
    .mix-rate,
    .wage-mix {
      background-color: white;
    }
    .premium-mix,
    .mix-rate,
    .wage-mix,
    .premium-mix-header,
    .mix-rate-header,
    .wage-mix-header {
      width: 12%;
    }
  }

  .state-table {
    margin-top: 30px;
  }

  .hazard-table,
  .state-table {
    td:nth-child(1) {
      text-align: center;
    }

    th:nth-child(2) {
      text-align: center;
    }

    .table-name {
      background-color: #a33336;
      text-align: center;
      vertical-align: middle;
      width: 11%;
    }

    .key,
    .key-header {
      width: 19.5%;
    }

    .payroll,
    .premium,
    .payroll-header,
    .premium-header {
      width: 15.5%;
    }

    .premium-mix,
    .mix-rate,
    .wage-mix,
    .premium-mix-header,
    .mix-rate-header,
    .wage-mix-header {
      width: 14%;
    }
  }

  .hazard-table,
  .state-table {
    width: 85%;
    float: right;
  }
}

@media only screen and (max-width: 1600px) {
  .hazard-table {
    .hazard-table,
    .state-table {
      width: 87%;
      float: right;
    }

    .table-name {
      width: 12% !important;
    }

    .key,
    .key-header {
      width: 17.5%;
    }

    .payroll,
    .premium,
    .payroll-header,
    .premium-header {
      width: 15.5%;
    }

    .premium-mix,
    .mix-rate,
    .wage-mix,
    .premium-mix-header,
    .mix-rate-header,
    .wage-mix-header {
      width: 14%;
    }
  }
}

@media only screen and (max-width: 1400px) {
  #riskprofile-table {
    .hazard-table,
    .state-table {
      width: 87%;
      float: right;
    }

    .hazard-table {
      .table-name {
        width: 15% !important;
      }

      .key,
      .key-header {
        width: 16.5%;
      }

      .payroll,
      .premium,
      .payroll-header,
      .premium-header {
        width: 15.5%;
      }

      .premium-mix,
      .mix-rate,
      .wage-mix,
      .premium-mix-header,
      .mix-rate-header,
      .wage-mix-header {
        width: 14%;
      }
    }

    .state-table {
      .table-name {
        width: 10% !important;
      }

      .key,
      .key-header {
        width: 18% !important;
      }

      .payroll,
      .premium,
      .payroll-header,
      .premium-header {
        width: 16% !important;
      }

      .premium-mix,
      .mix-rate,
      .wage-mix,
      .premium-mix-header,
      .mix-rate-header,
      .wage-mix-header {
        width: 15% !important;
      }
    }
  }
}

@media only screen and (max-width: 786px) {
  #riskprofile-table {
    .state-table,
    .hazard-table {
      width: 100% !important;
      border-collapse: collapse !important;
      float: none !important;
    }
  }
}

@media only screen and (max-width: 750px) {
  .riskProfile-table-responsive {
    overflow-x: auto !important;
  }

  .Spacing-rating {
    display: flex;
    gap: 2rem;
  }

  #cmprQts,
  #underwriting-page {
    padding-left: 0px !important;
    padding-right: 0px !important;

    .MuiTableCell-head {
      width: 190px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 190px !important;
    }
  }
}

@media only screen and (min-width: 1600px) {
  #cmprQts {
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
      max-width: 280px !important;
    }

    .MuiTableCell-head {
      width: 190px !important;
      max-width: 280px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
      max-width: 280px !important;
    }

    .MuiPaper-root > div:nth-child(2) ::-webkit-scrollbar {
      height: 18px !important;
      // background-color: #e2f2ff;
    }
  }

  #underwriting-page {
    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 210px !important;
      max-width: 210px !important;
    }

    .MuiPaper-root > div:nth-child(2) ::-webkit-scrollbar {
      height: 18px !important;
      // background-color: #e2f2ff;
    }

    .MuiTableRow-head {
      .MuiTableCell-head {
        width: 210px !important;
        max-width: 210px !important;
      }
    }

    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
      max-width: 280px !important;
    }
  }

  .quote_table_4header {
    width: 94.2% !important;
  }

  .quote_table_3header {
    width: 85% !important;
  }

  .quote_table_2header {
    width: 70% !important;
  }

  .quote_table_1header {
    width: 50% !important;
  }
}

@media only screen and (max-width: 1600px) {
  #cmprQts {
    .MuiTableCell-head {
      width: 220px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }

    // .MuiPaper-root > div:nth-child(2)
    //   ::-webkit-scrollbar-track-piece:start {
    //   margin-left: 150px;
    // }

    .MuiPaper-root > div:nth-child(2) ::-webkit-scrollbar {
      height: 18px !important;
      // background-color: #e2f2ff;
    }
  }

  #underwriting-page {
    .MuiTableRow-head {
      .MuiTableCell-head {
        width: 210px !important;
        max-width: 210px !important;
      }
    }

    .MuiTableRow-head > :nth-child(1) {
      width: 270px !important;
      max-width: 270px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 210px !important;
    }

    .MuiPaper-root > div:nth-child(2) ::-webkit-scrollbar {
      height: 18px !important;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 82% !important;
  }

  .quote_table_1header {
    width: 62% !important;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 1850px) {
  #underwriting-page {
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 180px !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.3rem;
    }

    .quotes-table {
      padding: 1rem !important;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 90% !important;
  }

  .quote_table_2header {
    width: 62% !important;
  }

  .quote_table_1header {
    width: 42% !important;
  }

  #cmprQts {
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }

    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1.5rem !important;
      padding: 15px 0px 15px 0px !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.3rem;
    }

    .quotes-table {
      padding: 1rem !important;
    }

    .quote_table_4header {
      width: 100% !important;
    }

    .quote_table_3header {
      width: 100% !important;
    }

    .quote_table_2header {
      width: 82% !important;
    }

    .quote_table_1header {
      width: 62% !important;
    }
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  #underwriting-page {
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 180px !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.3rem;
    }

    .quotes-table {
      padding: 1rem !important;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 82% !important;
  }

  .quote_table_1header {
    width: 62% !important;
  }

  #cmprQts {
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }

    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.3rem;
    }

    .quotes-table {
      padding: 1rem !important;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 82% !important;
  }

  .quote_table_1header {
    width: 62% !important;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  #cmprQts,
  #underwriting-page {
    .MuiTableCell-head {
      width: 320px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 320px !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.3rem;
    }

    .quotes-table {
      padding: 1rem !important;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 82% !important;
  }

  .quote_table_1header {
    width: 62% !important;
  }

  #cmprQts {
    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }

    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
  }
}

@media only screen and (min-width: 1080px) and (max-width: 1280px) {
  #cmprQts,
  #underwriting-page {
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 180px !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.3rem;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 87% !important;
  }

  .quote_table_1header {
    width: 79% !important;
  }

  #cmprQts {
    .quotes-table {
      padding-left: 1rem !important;
      padding-bottom: 1rem !important;
    }

    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }

    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
  }
}

@media only screen and (max-width: 1080px) {
  #cmprQts,
  #underwriting-page {
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 13px 0px 14px 0px !important;
    }

    .fontName-Size,
    .viewQuoteButton {
      font-size: 1.2rem;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 180px !important;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 95% !important;
  }

  .quote_table_1header {
    width: 85% !important;
  }

  #cmprQts {
    .quotes-table {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }

    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }

    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
  }
}

@media only screen and (min-width: 901px) and (max-width: 1079px) {
  #cmprQts,
  #underwriting-page {
    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 13px 0px 13px 0px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 180px !important;
    }

    .quotes-table {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.2rem;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 95% !important;
  }

  .quote_table_1header {
    width: 90% !important;
  }

  #cmprQts {
    .quotes-table {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }

    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }

    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  #cmprQts,
  #underwriting-page {
    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 0.9rem !important;
      }
    }

    .quotes-table {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    .fontName-Size1,
    .viewQuoteButton {
      font-size: 1.2rem;
    }

    .fontName-Size {
      font-size: 1.3rem !important;
    }

    .fontName-Size1 {
      font-size: 1.5rem !important;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 95% !important;
  }

  .quote_table_1header {
    width: 95% !important;
  }

  #cmprQts {
    .quotes-table {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }

    .MuiTableRow-head > :nth-child(1) {
      width: 280px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 280px !important;
    }

    .MuiTableCell-head {
      width: 180px !important;
      font-size: 1rem !important;
      padding: 15px 0px 15px 0px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  #cmprQts,
  #underwriting-page {
    .fontName-Size {
      font-size: 1.2rem !important;
    }

    .fontName-Size1 {
      font-size: 1.4rem !important;
    }

    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 0.9rem !important;
      }
    }

    .viewQuoteButton {
      font-size: 1.2rem;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 95% !important;
  }

  .quote_table_1header {
    width: 95% !important;
  }
}

@media only screen and (max-width: 680px) {
  #cmprQts,
  #underwriting-page {
    font-size: smaller !important;

    .MuiTableCell-head {
      width: 220px !important;
      padding: 10px 0px 10px 0px !important;
    }

    .quotes-table {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 220px !important;
    }

    .fontName-Size {
      font-size: 1.1rem !important;
    }

    .fontName-Size1 {
      font-size: 1.2rem !important;
    }

    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 0.9rem !important;
      }
    }

    .viewQuoteButton {
      font-size: 1.2rem;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 100% !important;
  }

  .quote_table_1header {
    width: 100% !important;
  }
}

@media only screen and (max-width: 580px) {
  #cmprQts,
  #underwriting-page {
    .fontName-Size {
      font-size: 0.9rem !important;
    }

    .fontName-Size1 {
      font-size: 1rem !important;
    }

    .MuiTableCell-head {
      width: 200px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 200px !important;
    }

    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 0.9rem !important;
      }
    }

    .quotes-table {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .viewQuoteButton {
      font-size: 1.2rem;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 100% !important;
  }

  .quote_table_1header {
    width: 100% !important;
  }

  #underwriting-page {
    .MuiTableRow-head > :nth-child(1) {
      width: 190px !important;
      max-width: 190px !important;
    }
  }
}

@media only screen and (max-width: 490px) {
  #cmprQts,
  #underwriting-page {
    .quotes-table {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .fontName-Size {
      font-size: 0.8rem !important;
    }

    .fontName-Size1 {
      font-size: 0.9rem !important;
    }

    .MuiTableCell-head {
      width: 185px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 185px !important;
    }

    .chekcbox-input {
      height: 15px !important;
      width: 15px !important;
    }

    .MuiTableRow-head {
      .MuiTableCell-head {
        font-size: 0.9rem !important;
      }
    }

    .viewQuoteButton {
      font-size: 1.2rem;
    }
  }

  .quote_table_4header {
    width: 100% !important;
  }

  .quote_table_3header {
    width: 100% !important;
  }

  .quote_table_2header {
    width: 100% !important;
  }

  .quote_table_1header {
    width: 100% !important;
  }

  #underwriting-page {
    .MuiTableRow-head > :nth-child(1) {
      width: 180px !important;
      max-width: 180px !important;
    }
  }
}

@media only screen and (max-width: 400px) {
  #cmprQts,
  #underwriting-page {
    .fontName-Size {
      font-size: 0.7rem !important;
    }

    .fontName-Size1 {
      font-size: 0.7rem !important;
    }

    .MuiTableCell-head {
      width: 95px !important;
    }

    .MuiPaper-root > div:nth-child(2) > div > div > div:nth-child(2) {
      width: 90px !important;
    }

    .chekcbox-input {
      height: 13px !important;
      width: 13px !important;
    }

    .spacing__row {
      display: none;
    }
  }
}

.peo-dropdown-container {
  input {
    width: 170px !important;
  }
}

.LoginUser {
  padding-left: 20px;
}

.multi-select-container {
  button {
    span:first-of-type {
      font-size: 16px;
    }
  }
}

.onClickQuoteToolBtn {
  background-color: #ffd13f;
  border-color: #eea236;
  box-shadow: 2px 4px 12px #eea236;
  color: #fff;
  white-space: nowrap;
}

.disable-div {
  pointer-events: none !important;
  opacity: 0.4;
}

.enable-div {
  cursor: pointer;
}

// UW dashbaord.
.dashboard-container,
.submission-uw-dashboard {
  .MuiPaper-root {
    box-shadow: 1px 1px 12px 0px;
  }

  padding-left: 10px;
  padding-right: 10px;
}

// @media only screen and (min-width: 1410px) {
//   .submission-uw-dashboard {
//     padding-left: 30px !important;
//     padding-right: 30px !important;
//   }
// }
// @media only screen and (min-width: 1500px) {
//   .submission-uw-dashboard {
//     padding-left: 40px !important;
//     padding-right: 40px !important;
//   }
// }
// @media only screen and (min-width: 1600px) {
//   .submission-uw-dashboard {
//     padding-left: 50px !important;
//     padding-right: 50px !important;
//   }
// }
// @media only screen and (min-width: 1700px) {
//   .submission-uw-dashboard {
//     padding-left: 70px !important;
//     padding-right: 70px !important;
//   }
// }
@media only screen and (min-width: 1800px) {
  .submission-uw-dashboard {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (min-width: 1900px) {
  .submission-uw-dashboard {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media only screen and (min-width: 2000px) {
  .submission-uw-dashboard {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}

@media only screen and (max-width: 900px) {
  .dashboard-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

///// end of Uw dashbaod

// rater Dashboard Begin here
@media only screen and (max-width: 900px) {
  .rater_dashboard {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media only screen and (min-width: 1000px) {
  .rater_dashboard {
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media only screen and (min-width: 1100px) {
  .rater_dashboard {
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

@media only screen and (min-width: 1200px) {
  .rater_dashboard {
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media only screen and (min-width: 1300px) {
  .rater_dashboard {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (min-width: 1400px) {
  .rater_dashboard {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media only screen and (min-width: 1500px) {
  .rater_dashboard {
    padding-left: 12%;
    padding-right: 12%;
  }
}

@media only screen and (min-width: 1600px) {
  .rater_dashboard {
    padding-left: 14%;
    padding-right: 14%;
  }
}

@media only screen and (min-width: 1700px) {
  .rater_dashboard {
    padding-left: 16%;
    padding-right: 16%;
  }
}

@media only screen and (min-width: 1800px) {
  .rater_dashboard {
    padding-left: 17%;
    padding-right: 17%;
  }
}

@media only screen and (min-width: 1900px) {
  .rater_dashboard {
    padding-left: 16%;
    padding-right: 16%;
  }
}

@media only screen and (min-width: 2000px) {
  .rater_dashboard {
    padding-left: 18%;
    padding-right: 18%;
  }
}

@media only screen and (min-width: 2100px) {
  .rater_dashboard {
    padding-left: 20%;
    padding-right: 20%;
  }
}

// rater Dashboard ends here

// @media only screen and (min-width: 1500px) {
//   .lib_dashboard {
//     padding-left: 20px !important;
//     padding-right: 20px !important;
//   }
// }
// @media only screen and (min-width: 1600px) {
//   .lib_dashboard {
//     padding-left: 100px !important;
//     padding-right: 100px !important;
//   }
// }
@media only screen and (min-width: 1800px) {
  .lib_dashboard {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (min-width: 1900px) {
  .lib_dashboard {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

////////////////////////////////////////////// end of agent dashbaord

.accord-container {
  margin-top: 10px;
  border: 1px solid #fff;
  box-shadow: 0.5px 1px 4px grey;
  background-color: #f1f1f1;
}

.accord-toolbar {
  padding: 10px;
  display: flex;
}

.accord-container-mobile {
  width: 20rem;
  height: 80px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 5px 12px grey;
  background-color: #faa541;
  font-size: 1rem;
  font-weight: bolder;
  margin-top: 3rem;
}

// .netrateimagefont {
//   font-size: 1.3vw;
// }
// @media only screen and (min-width: 400px) {
//   .netrateimagefont {
//     font-size: 5.5px !important;
//   }
// }
// @media only screen and (min-width: 500px) {
//   .netrateimagefont {
//     font-size: 7px !important;
//   }
// }
// @media only screen and (min-width: 600px) {
//   .netrateimagefont {
//     font-size: 8px !important;
//   }
// }

// @media only screen and (min-width: 700px) {
//   .netrateimagefont {
//     font-size: 10.6px !important;
//   }
// }
// @media only screen and (min-width: 800px) {
//   .netrateimagefont {
//     font-size: 11.6px !important;
//   }
// }
// @media only screen and (min-width: 900px) {
//   .netrateimagefont {
//     font-size: 12.3px !important;
//   }
// }
// @media only screen and (min-width: 1000px) {
//   .netrateimagefont {
//     font-size: 13.4px !important;
//   }
// }
// @media only screen and (min-width: 1100px) {
//   .netrateimagefont {
//     font-size: 14.4px !important;
//   }
// }
// @media only screen and (min-width: 1200px) {
//   .netrateimagefont {
//     font-size: 15.4px !important;
//   }
// }

// @media only screen and (min-width: 1300px) {
//   .netrateimagefont {
//     font-size: 16.4px !important;
//   }
// }
// @media only screen and (min-width: 1400px) {
//   .netrateimagefont {
//     font-size: 18.5px !important;
//   }
// }

// @media only screen and (min-width: 1500px) {
//   .netrateimagefont {
//     font-size: 19.3px !important;
//   }
// }
// @media only screen and (min-width: 1600px) {
//   .netrateimagefont {
//     font-size: 20.8px !important;
//   }
// }
// @media only screen and (min-width: 1700px) {
//   .netrateimagefont {
//     font-size: 22.8px !important;
//   }
// }
// @media only screen and (min-width: 1800px) {
//   .netrateimagefont {
//     font-size: 23.8px !important;
//   }
// }
// @media only screen and (min-width: 1900px) {
//   .netrateimagefont {
//     font-size: 25.5px !important;
//   }
// }

.eligibility_Netrate {
  padding-left: 22px !important;
  padding-right: 10px !important;
}

#carrier-select-container {
  button {
    height: 35px;
    color: black;
    padding-left: 5px;
    box-shadow: 0.5px 1px 4px grey;
    width: 100% !important;

    span:first-child {
      font-size: 16px;
    }
  }

  input {
    padding-left: 0;
    box-shadow: none !important;
  }
}

.react-select-carrier-container {
  button {
    span:first-child {
      color: #5d667b !important;
    }
  }
}

.acordSelectedList {
  ol {
    margin-left: -1rem !important;
    display: inline-flex !important;
    padding-left: 0px !important;
    position: relative;
    left: -45px;
    bottom: -50px;
  }

  ol,
  li {
    padding: 10px 10px 10px 10px !important;
    margin-left: 12px;
    margin-bottom: -10px;
    font-size: 1rem;
    font-weight: 600;
  }
}

.tabSelected {
  font-weight: 900;
  font-size: 1.1rem;
  box-shadow: 1.5px 1.5px 4px;
  margin: 0;
  padding: 0;
  background: whitesmoke;
}

.TabNameSelected {
  border-radius: 2px;
  // display: contents;
}

.small-text-quote-table {
  font-size: 16px;
}

.smaller-text-quote-table {
  font-size: 14px;
}

.recalculate-quote,
#underwriting-page,
#compareQuote {
  text-align: left;

  .constant-margin-top-bottom {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .text-align-left {
    text-align: left;
  }

  .green-text {
    color: #28a745;
  }

  .blue-text {
    color: #51a0d8;
  }

  .brown-text {
    color: #824704;
  }

  .red-text {
    color: #ab111b;
  }

  .dark-blue-text {
    color: blue;
  }

  .dark-blue {
    color: darkblue;
  }

  td,
  th {
    width: auto;
  }

  .switch_container {
    .custom-control-label::before {
      color: #fff;
      background-color: #e97d88;
      // border-color: #e97d88;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      // border-color: #28a745;
      background-color: #28a745;
    }

    .custom-switch .custom-control-label::after {
      background-color: #fff;
    }
  }

  .debit_credit_num {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .debit_credit_num_container {
    padding-inline: 10px;
  }
}

.submit-to-underwriter {
  text-align: left;
  padding-inline: 1rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.submit-uw-details {
  text-align: center;
  padding-inline: 5rem;
}

.submission-uw-dashboard {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: auto;
  margin-left: auto;
}

.sub_underwritter_approved_button,
.sub_underwritter_hold_button,
.sub_underwritter_rejected_button {
  color: #fff;
  border: none;
}

.sub_underwritter_approved_button {
  background-color: #008e1b;
}

.sub_underwritter_hold_button {
  background-color: #ffbd16;
}

.sub_underwritter_rejected_button {
  background-color: #cb2e3e;
}

.underwriter_comment {
  color: #000;
  padding-inline: 2px;
  box-shadow: 0.5px 1px 4px grey;
  border-color: white;
}

.dot {
  margin-top: 5%;
}

@media only screen and (max-width: 1250px) {
  .dot {
    margin-top: 0%;
  }
}

.worker:hover + .Legend {
  display: block;
}

.Legend {
  display: none;
  list-style-type: none;
}

.Legend-colorBox {
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.Legend-label {
  padding: 5%;
}

.uw-comment {
  width: 100%;
  font-size: 1rem;
  color: #000;
  padding-inline: 5px;
  box-shadow: 0.5px 1px 4px grey;
  border-color: white;
  margin-top: 0.3rem;
  font-family: "MontserratRegular";
}

.uw-options {
  width: 100%;
  font-size: 1rem;
  color: #000;
  padding-inline: 15px;
  box-shadow: 0.5px 1px 4px grey;
  border-color: white;
}

.MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel:hover {
  color: coral !important;
}

.MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc {
  fill: white !important;
}

.MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionDesc {
  fill: white !important;
}

/* .MuiTableCell-root.MuiTableCell-head.MTableHeader-header-14.MuiTableCell-alignLeft.MuiTableCell-sizeSmall:hover{
  color:coral !important;
} */

.scroll-to-left .scroll-to-right {
  position: absolute;
  top: 50%;
  bottom: 50%;
  z-index: 10;
}

.ChevronRight {
  position: fixed;
  right: 10%;
  width: 2.5%;
  bottom: 32%;
  z-index: 10;
  border: none;
  opacity: 0.5;
  border-radius: 50%;
  color: #312f2f;
  font-weight: bold;
  font-size: 30px;
  transition: 0.1s;
}

.ChevronLeft {
  position: fixed;
  left: 10%;
  width: 2.5%;
  bottom: 32%;
  z-index: 10;
  border: none;
  opacity: 0.5;
  border-radius: 50%;
  color: #312f2f;
  font-weight: bold;
  font-size: 30px;
  transition: 0.1s;
}

.ChevronLeft:hover {
  color: black !important;
  font-size: 32px;
  opacity: 0.9;
}

.ChevronRight:hover {
  color: rgb(37, 21, 21) !important;
  font-size: 32px;
  opacity: 0.9;
}

@media only screen and (max-width: 1600px) {
  .ChevronRight {
    // top: 78%;

    font-size: 30px;
    transition: 0.1s;
    width: 3%;
  }

  .ChevronLeft {
    // top: 78%;

    font-size: 30px;
    transition: 0.1s;
    width: 3%;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 32px;
  }

  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 32px;
  }
}

@media only screen and (max-width: 1400px) {
  .ChevronRight {
    font-size: 28px;
    width: 4%;
    display: none;
  }

  .ChevronLeft {
    font-size: 28px;
    width: 4%;
    display: none;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 30px;
  }

  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 30px;
  }
}

@media only screen and (max-width: 750px) {
  .ChevronRight {
    font-size: 25px;
    width: 5%;
    display: none;
  }

  .ChevronLeft {
    font-size: 25px;
    width: 5%;
    display: none;
  }

  .ChevronLeft:hover {
    color: black !important;
    font-size: 27px;
  }

  .ChevronRight:hover {
    color: rgb(37, 21, 21) !important;
    font-size: 27px;
  }
}

.MuiInputBase-input {
  font-size: 13px !important;
  color: "green" !important;
}

.MuiButtonBase-root.MuiTableSortLabel-root {
  color: white !important;
}

.MuiButtonBase-root.MuiTableSortLabel-root:hover {
  color: coral !important;
}

#statusDrop {
  background: transparent !important;
  color: black !important;
  border: 1px solid black !important;
  border-radius: 4px;
  padding: "0px 15px 0px 10px" !important;
  width: 100% !important;
  outline: "none" !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin: -4px !important;
  box-shadow: 0.5px 2px 2px gray;
}

@media only screen and (max-width: 1400px) {
  .UWdashboard_title {
    font-size: 2rem;
  }
}

// @media screen and (max-width: 485px) {
//   .row {
//     display: flex !important;
//     flex-direction: column !important;
//   }
//   .imagesizelogoicomp{
//     margin-right:0rem !important;
//   }
//   .imagesizelogo{
//     margin-left: 0rem !important;
//   }
// }

@media only screen and (max-width: 993px) {
  .worker_comp_code_input,
  #state {
    width: 98%;
    padding-left: 15px;
  }

  .address_delete_btn {
    margin-bottom: 2rem;
  }

  .delete_records_btn {
    margin-top: -2rem;
    margin-bottom: 3rem;
  }

  #expected-expiry-date {
    width: 100% !important;
  }

  .header-container {
    flex-direction: column;
  }

  .get-quote-uw {
    margin-top: 2rem;
    margin-left: -1rem !important;
  }
}

@media all and (min-width: 993px) and (max-width: 1370px) {
  .Workerscomp_margin {
    flex-wrap: nowrap !important;
  }
}

@media all and (min-width: 993px) and (max-width: 1170px) {
  .emod-ques-date {
    flex-wrap: nowrap;
  }
}

@media all and (min-width: 576px) and (max-width: 993px) {
  .emod-section-ques {
    flex-direction: row !important;
  }

  .emod-ques-date {
    width: 13rem !important;
    flex-direction: row !important;
    flex-wrap: nowrap;
  }

  .col-sm-7 {
    max-width: 49% !important;
  }
}

@media all and (min-width: 375px) and (max-width: 451px) {
  .imagesizelogo {
    height: 6rem !important;
    margin-left: 15px !important;
    margin-top: 10px;
  }

  .imagesizelogoicomp {
    height: 60px !important;
    margin-right: 0px !important;
  }

  .Landing__page__image {
    height: 300px !important;
  }
}

@media all and (min-width: 451px) and (max-width: 600px) {
  .imagesizelogo {
    height: 6rem !important;
    margin-left: 15px !important;
    margin-top: 10px;
  }

  .imagesizelogoicomp {
    height: 60px !important;
    margin-right: 0px !important;
  }

  .Landing__page__image {
    height: 350px !important;
  }
}

@media all and (min-width: 601px) and (max-width: 767px) {
  .imagesizelogo {
    height: 6rem !important;
    margin-left: 15px !important;
    margin-top: 10px;
  }

  .imagesizelogoicomp {
    height: 70px !important;
    margin-right: 0px !important;
  }

  .Landing__page__image {
    height: 400px !important;
  }
}

@media all and (min-width: 768px) and (max-width: 900px) {
  .imagesizelogo {
    height: 7rem !important;
    margin-left: 15px !important;
    margin-top: 10px;
  }

  .imagesizelogoicomp {
    height: 90px !important;
    margin-right: 0px !important;
  }

  .Landing__page__image {
    height: 600px !important;
  }

  .rater-save-btn {
    padding-left: 4rem !important;
  }

  .raterNameField {
    width: 10rem !important;
  }
}

@media all and (min-width: 901px) and (max-width: 1224px) {
  .imagesizelogo {
    height: 7rem !important;
    margin-left: 25px !important;
    // margin-top: 23px;
  }

  .imagesizelogoicomp {
    height: 90px !important;
    margin-right: 10px !important;
  }

  .Landing__page__image {
    height: 600px !important;
  }

  .rater-save-btn {
    padding-left: 2rem !important;
  }

  .raterNameField {
    width: 10rem !important;
  }
}

@media all and (min-width: 1225px) and (max-width: 1440px) {
  .imagesizelogo {
    height: 7rem !important;
    margin-left: 25px !important;
    //margin-top: 20px;
  }

  .imagesizelogoicomp {
    height: 90px !important;
    margin-right: 30px !important;
  }

  .Landing__page__image {
    height: 550px !important;
  }
}

@media all and (min-width: 1441px) {
  .imagesizelogo {
    height: 100px !important;
    margin-left: 25px !important;
    margin-top: 15px;
  }

  .imagesizelogoicomp {
    height: 100px !important;
    margin-right: 30px !important;
  }

  .Landing__page__image {
    height: 550px !important;
  }
}

.start-new-quote-uw {
  margin-right: 1rem !important;
}

.update-rates {
  padding-inline: 4.5rem;

  .update-rates-heading {
    color: #497fbc;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
    display: flex;
  }

  th {
    background-color: #c39d97;
    color: #ffffff;
    vertical-align: middle;
    font-size: 14px;
    font-family: "montserratMedium";
    padding: 10px;
    font-weight: 400;
    text-align: center;
  }

  td {
    padding: 10px;
    font-family: "montserratMedium";
    text-align: center;
    font-size: 13px;
    // float:inline-end;
  }

  .table {
    display: table;
    table-layout: fixed;
  }

  .description-details {
    width: 30%;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .state-type {
    width: 11%;
  }

  .class-code {
    width: 11%;
  }

  .manual-rate {
    width: 10%;
  }

  .netrate-uw-input-box {
    width: 100%;
    text-align: center;
  }

  .carr-dropdown-container {
    width: 100%;

    margin-bottom: 10px;

    label {
      font-weight: 400;
      font-family: "montserratMedium";
    }

    #carr-dropdown {
      width: 150px;
      font-size: 1rem;
      color: #000;
      padding-inline: 15px;
      box-shadow: 0.5px 1px 4px grey;
      border-color: white;
      margin-left: 10px;
      text-align: left;
    }
  }
}

.add-carrier {
  text-align: flex-end;
  padding: 1em 0;
}

.text-align-center {
  text-align: center;
}

//  toggle switch CSS and coloring
$label-colour: #c65124;
$toggle-colour: #2ea44f;
$white: #fff;
$focus-color: #ff0;

.toggle-switch {
  position: relative;
  margin-right: 10px;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;

  &-checkbox {
    display: none;
  }

  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid $label-colour;
    border-radius: 20px;
    margin: 0;

    &:focus {
      outline: none;

      > span {
        box-shadow: 0 0 2px 5px red;
      }
    }

    > span:focus {
      outline: none;
    }
  }

  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }

    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: $toggle-colour;
      color: $white;
      font-size: inherit !important;
    }
  }

  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: $label-colour;
    color: $white;
    text-align: right;
    font-size: inherit !important;
  }

  &-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid $label-colour;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }

  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }

    .toggle-switch-switch {
      right: 0px;
    }
  }

  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }

  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }

  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
}

//// end of toggle switch css

.comment-btn {
  border: 1px solid #ffbd16;
  border-radius: 5px;
  // font-size: 20px !important;
  margin-left: 10px !important;
}

.comment-btn:hover {
  border: 1px solid #ffffff;
  background-color: #ffbd16;
  color: #ffffff;
  border-radius: 5px;
}

.MuiDialogTitle-root {
  padding: 17px 85px !important;
}

// .CommentTable{
//   width: 100%;
// }

// .comment-table-heading>tr th{
//   width: 70%;
//   padding: 0px 10px;
// }

.CommentTable {
  width: 100%;
  border-collapse: collapse;
}

.comment-table-heading th {
  background-color: #ffbd16;
  padding: 10px;
}

.comment-table-heading th:nth-child(2) {
  background-color: #ffbd16;
  padding: 10px 30px;
}

.even-row {
  background-color: #f9f9f9;
}

.odd-row {
  background-color: #ffffff;
}

.CommentTable td {
  padding: 10px;
  text-align: center;
  border: 1px solid #dddddd;
}

.CommentTable td:first-child {
  text-align: left;
}

.comment-table-body {
  font-size: 12px !important;
}

.MuiDialogContent-root {
  overflow-y: overlay !important;
  max-height: 15em !important;
}

.payrollBox {
  width: 100%;
  padding-right: 2rem;
}

.releasedPayrollBox {
  width: 100%;
  padding-right: 12rem;
}

.cream-color {
  background-color: #fff2cc;
}

.small-box {
  width: 55px;
  margin-right: 15px;
  border: 1px solid #0000008f;
}

.quote-color-indication-info-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeSmall {
  button {
    padding-top: 0.375rem !important;
  }
}

.notes-row {
  padding-inline: 15px;
  margin-top: -15px;
  margin-left: 2.5rem;

  .form-group {
    width: 100%;
  }
}

.download-link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.sub_headings {
  color: #a33336;
  font-size: 19px;
}

.locAndPayroll {
  margin-top: 0;
  margin-left: 4rem;
}

.uw-heading-box {
  padding-left: 0;
  display: flex;
  align-items: center;
  padding-bottom: 0;
}

.peoChargefont {
  font-size: 12px;
}

#pricingSummary {
  table {
    width: 100%;
    table-layout: auto;
    border-collapse: separate;
    border-spacing: 15px;
    background-color: #ebebeb;
  }
  thead {
    tr {
      background-color: #e7a4a6;
      font-size: 16px !important;
    }
    th {
      padding: 7px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
  }
  tbody {
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 5px;
      background-color: white;
      border-width: 8px;
      border-color: #ebebeb;
      font-size: 14px !important;
    }
  }
}

.MuiInputBase-root.MuiOutlinedInput-root {
  height: 1.6rem;
  border-bottom: 1px solid black;
  border-radius: 0px !important;
  padding: 0px !important;
}
.MuiInputBase-root.MuiOutlinedInput-root {
  height: 1.6rem;
  border-bottom: 1px solid black;
  border-radius: 0px !important;
  padding: 0px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root input {
  font-size: 11px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root input::placeholder {
  font-size: 11px !important;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.downloadFilesBtn {
  text-decoration: none;
  color: white;
  background-color: rgb(179, 75, 62);
  margin-top: 10px;
}

.downloadFilesBtn:hover {
  background-color: rgb(203, 87, 52);
  color: white;
}

.fileNameBtn {
  text-decoration: none;
}

.fileNameBtn:hover {
  text-decoration: underline;
}

.approveBtnSubmits {
  border-radius: 25px;
  background-color: #1ad60c;
  border-color: #1ad60c;
  color: white;
  font-size: 1rem;
  font-weight: 800;
}
.rejectBtnSubmits {
  border-radius: 25px;
  background-color: #f71d1d;
  border-color: #f71d1d;
  color: white;
  font-size: 1rem;
  font-weight: 800;
}

.ies_dashboard {
  table {
    tbody,
    tr,
    td {
      padding: 0px 4px !important;
    }
  }
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeSmall
  button {
  padding-top: 0px !important;
}
.MuiOutlinedInput-input {
  padding: 0 !important;
}

.MuiInputAdornment-sizeMedium {
  margin: 0px !important;
}

.quoteDatepicker {
  .react-datepicker-wrapper {
    width: 50%;
    height: 70%;
  }
  .react-datepicker__input-container {
    width: 100%;
    height: 70%;
    input {
      width: 100% !important;
      height: 70%;
    }
  }
}

@media only screen and (max-width: 480px) {
  #compPro,
  #undrQues,
  #viewProposal {
    margin-bottom: 28px !important;
    .acordParseform {
      margin-left: 1rem;
      margin-top: 3rem;

      .acord_input {
        font-size: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: white;
      }

      .acordSubmitButton {
        font-size: 14px;
        background-color: #a33336;
        border-color: #a33336;
        margin-left: 1rem;
      }
    }

    .compProHeading {
      margin-left: 0.5rem;
      color: #7a7878;
      font-size: 15px;
    }
  }
}

.position-relative {
  border: 1px solid transparent;
}

.lablestyle {
  font-weight: bolder;
  font-family: MontserratRegular;
  color: #a33336;
}

.input-field-login {
  border-radius: 10px !important;
  height: 50px !important;
  background-color: white !important;
}

.login-background {
  position: relative; /* Ensure the pseudo-element is positioned correctly */
  height: fit-content;
  background-image: url("../images/background-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1; /* Ensure content remains above the overlay */
}

.login-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Adjust this to darken the image */
  z-index: -1; /* Ensure the overlay stays below the content */
}

.btnAlignDow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media only screen and (max-width: 767px) {
  .btnAlignDow {
    display: grid;
  }
}

.datePickerError {
  input {
    border: 1px solid red; // This targets the input directly
  }
}

.modal-footer{
  width: 90% ;
}
