.button-container {
    position: relative; 
    display: inline-block;
    padding: 6px;
}

.info-icon {
    color: #fff; 
    border: 0px solid #000;
    border-radius: 50px; 
    margin-left: 5px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.my-icon-class {
    color: #1d1c1c;
    font-size: 20px;
  }



