.tableContainer {
  table {
    text-align: center;
    vertical-align: middle;
    .description {
      // width: 100%;
    }
    thead {
      th {
        white-space: nowrap;
        vertical-align: middle;
      }
      th:not(:last-child) {
        border-right: 0.1px solid black;
      }
    }
  }
}

.sectionContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  input {
    max-width: 100px;
    max-height: 35px;
    text-align: center;
  }
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.calculateSection {
  display: flex;
  justify-content: center;
  gap: 1rem;
  button {
    min-width: fit-content;
  }
}
